import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { cloneDeep } from 'lodash-es';
import { FormComponent } from '../../../../modules/forms/components/form/form.component';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { DeleteNodeSubitemComponent } from '../../dialogs/delete-node-subitem/delete-node-subitem.component';

@Component({
    selector: 'elias-editor-node-subitem-editor',
    templateUrl: './node-subitem-editor.component.html',
    styleUrls: ['./node-subitem-editor.component.scss'],
})
export class NodeSubitemEditorComponent implements OnInit {
    @Input() config: FormConfig = { elements: [] };
    @Input() data;
    @Input() isDelete = true;
    @Input() source = '';
    @Input() type = '';

    @Output() delete = new EventEmitter<any>();
    @Output() save = new EventEmitter<any>();

    loaded$ = new BehaviorSubject<boolean>(true);

    isSaving = false;
    newData;

    @ViewChild('form') form: FormComponent;

    constructor(public dialog: MatDialog, private sideNavService: SideNavService) {}

    ngOnInit() {
        this.newData = cloneDeep(this.data);
        if (this.type === 'keyfigure' && this.source === 'add') {
            this.newData.title = '';
            this.newData.number = '';
            this.newData.unit = '';
            this.newData.description = '';
        }
    }

    remove() {
        const modalRef = this.dialog.open(DeleteNodeSubitemComponent);
        modalRef.afterClosed().subscribe((remove) => {
            if (remove) {
                this.delete.emit(this.data);
            }
        });
    }

    onFormSubmitted(props) {
        this.isSaving = true;
        this.save.emit(props);
    }

    close() {
        this.sideNavService.close();
    }
}
