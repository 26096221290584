<elias-shared-multi-step-dialog (cancel)="onCancel()">
    <elias-shared-step
        (stepIncreased)="onConfirm()"
        [isSave]="true"
        [hideBackButton]="true"
        [hideDots]="true"
        title="{{ 'modal.confirm.isDataProcessingApproved.title' | translate }}"
        subtitle="Version 01 – Januar 2024"
        nextButtonLabel="{{ 'actions.agree' | translate }}">
        <elias-data-processing-content></elias-data-processing-content>
    </elias-shared-step>
</elias-shared-multi-step-dialog>
