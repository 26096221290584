import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'elias-shared-search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent implements OnInit, OnDestroy {
    @Input() placeholder: string = '';
    @Output() searchChange = new EventEmitter<string>();

    public hasFocus = false;
    public searchControl = new FormControl('');
    public searchSubscription: Subscription;

    get isActive(): boolean {
        const value = this.searchControl.value;
        return this.hasFocus || (value && value.trim() !== '');
    }

    ngOnInit(): void {
        this.searchSubscription = this.searchControl.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((value: string) => {
                this.searchChange.emit(value);
            });
    }

    public clearSearch(): void {
        this.searchControl.setValue('');
        this.searchChange.emit('');
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
    }
}
