import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';
import { EnvService } from './services/env.service';
import { environment } from '../../../environments/environment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpdateDetectedComponent } from '../shared/dialogs/update-detected/update-detected.component';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {
    constructor(private envService: EnvService, private dialog: MatDialog) {
        super({
            showDialog: envService.sentryShowDialog,
            logErrors: !environment.production,
        });
    }

    override handleError(error: unknown) {
        this.handleChunkLoadError(error);

        super.handleError(error);
    }

    private handleChunkLoadError(error: unknown): void {
        if (!(error instanceof Error)) {
            return;
        }

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (!chunkFailedMessage.test(error.message)) {
            return;
        }

        this.dialog.open(UpdateDetectedComponent, {
            position: { bottom: '50px', left: '50px' },
            width: '350px',
            height: '180px',
            hasBackdrop: false,
        });
    }
}
