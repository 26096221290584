import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

@Component({
    selector: 'elias-shared-step',
    template: `<ng-template><ng-content></ng-content></ng-template>`,
})
export class StepComponent extends CdkStep {
    @Input() hideBackButton = false;
    @Input() hideDots = false;
    @Input() isLoading = false;
    @Input() isSave = false;
    @Input() nextButtonLabel?: string;
    @Input() subtitle = '';
    @Input() title: string;

    @Output() stepIncreased = new EventEmitter<void>();

    public nextStep(): void {
        this.stepIncreased.emit();
    }
}
