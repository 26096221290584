<elias-standard-dialog
    [title]="selectedStep.title"
    [subtitle]="selectedStep.subtitle"
    (dialogClosed)="onCancel()"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="autofocus">
    <!-- Content from the CdkStep is projected here -->
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>

    <ng-container footer>
        <ng-container *ngIf="!selectedStep.hideBackButton">
            <elias-shared-basic-button
                *ngIf="selectedIndex === 0"
                (buttonClicked)="onCancel()"
                class="stepper-btn prev"
                size="big">
                {{ 'stepper.cancel' | translate }}
            </elias-shared-basic-button>
            <elias-shared-basic-button
                *ngIf="selectedIndex > 0"
                (buttonClicked)="previous()"
                class="stepper-btn prev"
                size="big">
                {{ 'stepper.previous' | translate }}
            </elias-shared-basic-button>
        </ng-container>

        <div class="step-dot-container" *ngIf="!selectedStep.hideDots">
            <div
                class="step-dot"
                *ngFor="let step of steps; trackBy: trackByIndex; let i = index"
                [ngClass]="{ active: selectedIndex === i }"
                (click)="onClick(i)"></div>
        </div>

        <elias-shared-flat-button
            (buttonClicked)="next()"
            [disabled]="!selectedStep.isSave"
            [loading]="selectedStep.isLoading"
            class="stepper-btn next"
            data-cy="stepper-next"
            color="accent"
            size="big">
            {{ selectedStep.nextButtonLabel ?? 'stepper.next' | translate }}
        </elias-shared-flat-button>
    </ng-container>
</elias-standard-dialog>
