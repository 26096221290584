import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MultiStepDialogComponent } from '../../../../modules/shared/dialogs/multi-step-dialog/multi-step-dialog.component';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { SectionTypeForm } from '../../forms/section-type.form';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { Node } from '../../models/node/node.model';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { AnimationOptions } from 'ngx-lottie';
import { FormComponent } from '../../../../modules/forms/components/form/form.component';
import { HistoryIconsMap } from '../../../history/maps/history-icons.map';
import { LayoutService, SectionsService } from '../../services';
import { SectionDto } from '../../models/dto/section-dto.model';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { ConfirmationForm } from '../../forms/confirmation.form';
import { Section } from '../../models/section.model';

@Component({
    selector: 'elias-change-section-type',
    templateUrl: './change-section-type.component.html',
    styleUrls: ['./change-section-type.component.scss'],
})
export class ChangeSectionTypeComponent implements OnInit {
    @ViewChild(MultiStepDialogComponent) stepper?: MultiStepDialogComponent;
    @ViewChild('sectionTypeFormComponent') sectionTypeFormComponent?: FormComponent;

    public sectionTypeFormConfig: FormConfig = { elements: [] };
    public confirmationFormConfig: FormConfig = this.confirmationForm.getConfiguration();
    public nodeIcons = HistoryIconsMap;
    public invalidNodes: Node[] = [];
    public newSectionType?: string;
    public options: AnimationOptions = {
        path: './assets/images/animation/success-check.json',
        autoplay: true,
        loop: false,
    };
    public section: Section;
    public isSaving = false;

    constructor(
        public confirmationForm: ConfirmationForm,
        public dialogRef: MatDialogRef<ChangeSectionTypeComponent>,
        private layoutService: LayoutService,
        private nodesQuery: NodesQuery,
        private publicationsQuery: PublicationsQuery,
        private sectionsService: SectionsService,
        private sectionTypeForm: SectionTypeForm,
        private sideNavService: SideNavService,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.section = data.section;
    }

    ngOnInit(): void {
        this.sectionTypeFormConfig = this.sectionTypeForm.getConfiguration(
            this.section,
            this.publicationsQuery.getActive()
        );
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public visualizeDeletedNodes(): void {
        this.newSectionType = this.sectionTypeFormComponent?.formGroup?.get('sectionType')?.value;

        const allowedNodeTypes = this.layoutService.getAllowedNodeTypes(
            this.newSectionType!,
            this.publicationsQuery.getActive()
        );
        this.invalidNodes = this.nodesQuery.getAll().filter((node) => !allowedNodeTypes.includes(node.type));

        if (this.invalidNodes.length === 0) {
            this.stepper?.goToNext();
            this.stepper?.getActiveStep().nextStep();
        }
        this.stepper?.goToNext();
    }

    public async navigationAndClose(): Promise<void> {
        this.dialogRef.close(true);
    }

    public changeType(): void {
        const payload = new SectionDto({ sectionId: this.section.id }, {}, { type: this.newSectionType });
        this.isSaving = true;

        this.sectionsService.changeSectionType(payload).subscribe(() => {
            this.isSaving = false;
            this.stepper?.goToNext();
            this.sideNavService.close();
        });
    }

    public trackNodeById(index: number, node: Node): string {
        return node.id;
    }
}
