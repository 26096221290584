import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { interval, Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../../../auth/service/auth.service';
import { fromLocalStorage } from '../../observables/fromLocalStorage';
import { LocalStorageKeys } from '../../enums/local-storage-keys.enum';

@Component({
    selector: 'elias-shared-dialog-auto-logout',
    templateUrl: 'auto-logout.component.html',
})
export class AutoLogoutComponent implements OnInit, OnDestroy {
    public static readonly AUTOLOGOUT_OFFSET = 30 * 1000;
    public timeToLogout: number;

    private initialTimeToLogout = AutoLogoutComponent.AUTOLOGOUT_OFFSET / 1000;
    private autoLogoutTimerSubscription = new Subscription();
    private countdownIntervalSubscription = new Subscription();

    constructor(public dialogRef: MatDialogRef<AutoLogoutComponent>, private authService: AuthService) {
        this.dialogRef.disableClose = true;
        this.syncAutoLogoutWithLocalStorage();

        this.timeToLogout = this.initialTimeToLogout;
    }

    ngOnInit() {
        this.countdownIntervalSubscription = interval(1000)
            .pipe(
                tap(() => {
                    this.timeToLogout -= 1;

                    if (this.timeToLogout < 0) {
                        this.timeToLogout = 0;
                    }
                })
            )
            .subscribe();

        this.autoLogoutTimerSubscription = timer((this.initialTimeToLogout + 1) * 1000).subscribe(() => {
            localStorage.removeItem(LocalStorageKeys.AutoLogoutStartDate);

            this.authService.logout();
            this.dialogRef.close();
        });
    }

    stayLoggedIn() {
        localStorage.setItem(LocalStorageKeys.AutoLogoutCancelled, new Date().toString());
        this.dialogRef.close(true);
    }

    ngOnDestroy() {
        this.autoLogoutTimerSubscription.unsubscribe();
        this.countdownIntervalSubscription.unsubscribe();

        localStorage.removeItem(LocalStorageKeys.AutoLogoutStartDate);
        localStorage.removeItem(LocalStorageKeys.AutoLogoutCancelled);
    }

    private syncAutoLogoutWithLocalStorage() {
        const currentDate = new Date();

        if (localStorage.getItem(LocalStorageKeys.AutoLogoutStartDate) === null) {
            localStorage.setItem(LocalStorageKeys.AutoLogoutStartDate, currentDate.toString());
        } else {
            const localStorageAutoLogoutStartDate = Date.parse(
                localStorage.getItem(LocalStorageKeys.AutoLogoutStartDate) as string
            );
            this.initialTimeToLogout -= Math.round((currentDate.getTime() - localStorageAutoLogoutStartDate) / 1000);
        }

        fromLocalStorage(LocalStorageKeys.AutoLogoutCancelled).subscribe(() => {
            this.dialogRef.close();
        });
    }
}
