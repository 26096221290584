import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

type Color = 'accent' | 'warn';

@Component({
    selector: 'elias-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
    @Input() buttonLabel?: string;
    @Input() cancellable: boolean = true;
    @Input() color: Color = 'warn';
    @Input() description?: string;
    @Input() errorMessage?: string;
    @Input() expectedValue: string = 'delete';
    @Input() hint?: string;
    @Input() label?: string;
    @Input() strictMode: boolean = false;
    @Input() title?: string;

    @Output() dialogSubmitted = new EventEmitter<void>();

    public confirmFormGroup: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {
        this.confirmFormGroup = this.fb.group({});
    }

    ngOnInit(): void {
        if (this.strictMode) {
            this.confirmFormGroup.addControl('confirmation', this.fb.control('', [this.valueValidatorFn()]));
        }
    }

    public onSubmit(): void {
        this.dialogSubmitted.emit();
    }

    private valueValidatorFn(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currentValue = control.value.toLowerCase();
            const targetValue = this.expectedValue.toLowerCase();

            return currentValue === targetValue ? null : { valueError: true };
        };
    }
}
