import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../modules/shared/shared.module';
import { AssetBrowserComponent } from './components/asset-browser/asset-browser.component';
import { AssetBrowserFilesComponent } from './components/asset-browser-files/asset-browser-files.component';
import { FullscreenAssetBrowserComponent } from './pages/fullscreen-asset-browser/fullscreen-asset-browser.component';
import { AssetBrowserRoutingModule } from './asset-browser-routing.module';
import { AssetManagementComponent } from './components/asset-management/asset-management.component';
import { EliasFormsModule } from '../../modules/forms/forms.module';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatPaginatorIntlLocale } from './components/asset-browser-files/MatPaginatorIntlLocale';
import { AssetDropzoneUploadComponent } from './components/asset-dropzone-upload/asset-dropzone-upload.component';
import { AssetBrowserDataResolver } from './resolvers/asset-browser-data.resolver';
import { AssetBrowserDeepLinksResolver } from './resolvers/asset-browser-deep-links.resolver';
import { AssetBrowserFileComponent } from './components/asset-browser-file/asset-browser-file.component';
import { AssetManagementSidenavComponent } from './components/asset-management-sidenav/asset-management-sidenav.component';
import { ImageCroppingModule } from '../image-cropping/image-cropping.module';
import { DeleteAssetComponent } from './dialogs/delete-asset/delete-asset.component';

@NgModule({
    imports: [CommonModule, SharedModule, AssetBrowserRoutingModule, EliasFormsModule, ImageCroppingModule],
    declarations: [
        AssetBrowserComponent,
        AssetBrowserFilesComponent,
        AssetBrowserComponent,
        FullscreenAssetBrowserComponent,
        AssetManagementComponent,
        AssetDropzoneUploadComponent,
        AssetBrowserFileComponent,
        AssetManagementSidenavComponent,
        DeleteAssetComponent,
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlLocale,
        },
        AssetBrowserDataResolver,
        AssetBrowserDeepLinksResolver,
    ],
})
export class AssetBrowserModule {}
