import { Component, EventEmitter, Input, Output } from '@angular/core';

type Color = 'accent' | 'primary' | 'warn';

@Component({
    selector: 'elias-accent-dialog',
    templateUrl: './accent-dialog.component.html',
    styleUrls: ['./accent-dialog.component.scss'],
})
export class AccentDialogComponent {
    @Input() color: Color = 'warn';
    @Input() showCloseButton: boolean = true;
    @Input() title?: string;

    @Output() dialogClosed = new EventEmitter<void>();

    public closeDialog(): void {
        this.dialogClosed.emit();
    }
}
