<div fxLayout="row" class="action-buttons" fxLayoutGap="15%" *ngIf="isVisible" @slideInOut>
    <elias-shared-flat-button
        (buttonClicked)="onSubmit()"
        [loading]="isSaving"
        class="btn-save"
        data-cy="btn-save"
        data-karma="btn-save"
        color="accent">
        {{ 'actions.save' | translate }}
    </elias-shared-flat-button>
    <elias-shared-basic-button (buttonClicked)="onCancel()" class="btn-save" data-karma="btn-cancel">
        {{ 'actions.cancel' | translate }}
    </elias-shared-basic-button>
    <div class="changes" *ngIf="numberOfChanges === 1">{{ numberOfChanges }} {{ 'shared.change' | translate }}</div>
    <div class="changes" *ngIf="numberOfChanges > 1">{{ numberOfChanges }} {{ 'shared.changes' | translate }}</div>
</div>
