<elias-base-dialog
    colorTheme="dark"
    [showCloseButton]="showCloseButton"
    (dialogClosed)="closeDialog()"
    class="color-{{ color }}">
    <div class="header" header>
        <h1>{{ title }}</h1>
    </div>

    <ng-content></ng-content>

    <ng-container footer>
        <ng-content select="[footer]"></ng-content>
    </ng-container>
</elias-base-dialog>
