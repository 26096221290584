import { AfterViewInit, Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Node } from '../../models/node/node.model';
import {
    ActivatedRoute,
    ActivationStart,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    Router,
} from '@angular/router';
import { NodeDto } from '../../models/dto/node-dto.model';
import { Section } from '../../models/section.model';
import { Chart } from '../../models/chart.model';
import { DocumentService } from '../../services/document.service';
import { NodesService } from '../../services';
import { TreeService } from '../../services/tree.service';
import { DragulaService } from 'ng2-dragula';
import { SectionsQuery } from '../../state/sections/sections.query';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { ChartsQuery } from '../../state/charts/charts.query';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { delay, first, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, merge, Observable, race } from 'rxjs';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { Publication } from '../../../../publication/models/publication.model';
import { Location } from '@angular/common';
import { UiService } from '../../../../modules/core/services/ui.service';
import { NodesStore } from '../../state/nodes/nodes.store';
import { DataService } from '../../services/data.service';
import { UrlParamsHandlerInterface } from '../../../../modules/links/interfaces/url-params-handler.interface';
import { UrlParamsService } from '../../../../modules/links/services/url-params.service';
import { NodeCommentsSidenavService } from '../../../comments/services/sidenav/node-comments-sidenav.service';
import { SectionCommentsSidenavService } from '../../../comments/services/sidenav/section-comments-sidenav.service';

@Component({
    selector: 'elias-editor-document',
    styleUrls: ['./document.component.scss'],
    templateUrl: './document.component.html',
})
export class DocumentComponent implements OnInit, OnDestroy, AfterViewInit, UrlParamsHandlerInterface {
    @HostBinding('lang') langAttribute?: string;

    section$: Observable<Section>;
    charts$: Observable<Chart[]>;
    section: Section;

    selectedNode: Node | null;
    loaded$ = new BehaviorSubject<boolean>(true);
    nodePresetType;
    position;
    globalSource;
    inEditable = false;
    active: number;
    publication: Publication;

    private sectionId: string;

    get backgroundColor(): `#${string}` {
        return this.section.backgroundColor && !this.section.backgroundAssetImage
            ? `#${this.section.backgroundColor}`
            : '#ffffff';
    }

    get isInverted(): boolean {
        return this.section.type === 'inverted';
    }

    constructor(
        private chartsQuery: ChartsQuery,
        private data: DataService,
        private documentService: DocumentService,
        private dragulaService: DragulaService,
        private nodeCommentsSidenavService: NodeCommentsSidenavService,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private nodesStore: NodesStore,
        private publicationsQuery: PublicationsQuery,
        private route: ActivatedRoute,
        private router: Router,
        private sectionCommentsSidenav: SectionCommentsSidenavService,
        private sectionsQuery: SectionsQuery,
        private treeService: TreeService,
        private uiService: UiService,
        private urlParamsService: UrlParamsService,
        public dialog: MatDialog,
        public location: Location
    ) {
        this.publication = this.publicationsQuery.getActive() as Publication;
    }

    ngOnInit() {
        this.langAttribute = this.publication.locale;

        this.section$ = this.sectionsQuery.selectActive() as Observable<Section>;
        this.section$.subscribe((section) => {
            if (section) {
                this.section = section;
            }
        });

        this.nodesQuery
            .selectAll({
                filterBy: (entity) => entity.editing === true,
            })
            .pipe(map((res) => res))
            .subscribe((data) => {
                this.active = data.length;
            });

        this.nodesQuery.select('selectedNode').subscribe((selectedNode: Node) => {
            this.selectedNode = selectedNode;
        });

        // TODO: Move to EditorComponent if possible
        this.route.params.pipe(first()).subscribe((params) => {
            if (params.sectionId) {
                if (!params.type || !params.elementId) {
                    this.treeService.setActive(params.sectionId);
                }
            }
        });

        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof ActivationStart: {
                    this.loaded$.next(false);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loaded$.next(true);
                    break;
                }
                default: {
                    break;
                }
            }
        });

        this.charts$ = this.chartsQuery.selectAll();
        this.nodesQuery.select('loadedNodesForSectionId').subscribe((sectionId) => {
            if (sectionId) {
                this.sectionId = sectionId;
            }
        });
        const bag: any = this.dragulaService.find('bag-draggable');
        if (bag === undefined) {
            this.dragulaService.createGroup('bag-draggable', {
                accepts: (el, target, source, sibling) => {
                    if (this.nodesQuery.hasEntity('temp-100')) {
                        this.nodesStore.remove('temp-100');
                    }
                    return target.id === 'droppable' || (source.id === 'droppable' && target.id === 'droppable');
                },

                copy: (item) => {
                    return item.id === 'draggable';
                },

                moves: (el, source, handle, siblings) => {
                    return (
                        handle.classList.contains('node-button-drag') ||
                        handle.classList.contains('pe-preset') ||
                        handle.classList.contains('label') ||
                        handle.classList.contains('ei-150')
                    );
                },
            });

            this.dragulaService.drag('bag-draggable').subscribe(({ name, el, source }) => {
                document.onmousemove = (e) => {
                    this.globalSource = source;

                    const event = e || window.event;
                    const mouseY = event['pageY'];
                    const scrollTop =
                        window.scrollY ||
                        window.pageYOffset ||
                        document.body.scrollTop +
                            ((document.documentElement && document.documentElement.scrollTop) || 0);
                    const scrollBottom = scrollTop + window.innerHeight;
                    const elementHeight = (el as HTMLElement).getBoundingClientRect().height;

                    if (mouseY < scrollTop + 93) {
                        document.querySelector('.scroll-container').scrollBy(0, -15);
                    } else if (mouseY + elementHeight > scrollBottom) {
                        document.querySelector('.scroll-container').scrollBy(0, 15);
                    }
                };
            });

            this.dragulaService.dragend('bag-draggable').subscribe((value) => {
                document.onmousemove = null;
            });

            this.dragulaService.drop('bag-draggable').subscribe(({ name, el, target, source, sibling }) => {
                if (el != null) {
                    if (target != null) {
                        if ((el as HTMLElement).dataset.items && target.id === 'droppable') {
                            this.onDrop(el, target);
                        } else {
                            this.onNodeMove(el, target);
                        }
                    }
                }
            });

            this.dragulaService.cloned('bag-draggable').subscribe(({ name, clone, original, cloneType }) => {
                // console.log(name, clone, original, cloneType);
                if (cloneType === 'copy') {
                    // TODO: replace icon
                    clone.innerHTML =
                        '<p class="pe-node-placeholder"><i class="' +
                        (original.childNodes[0] as Element).classList.value +
                        '"></p>';
                }
            });
        }

        this.documentService.setLoading(false);
    }

    ngAfterViewInit() {
        this.documentService.loading$.pipe(switchMap(() => this.handleUrlParams())).subscribe();
    }

    handleUrlParams(): Observable<any> {
        const scrollToNode$ = this.urlParamsService.getParamFromURL('node').pipe(
            first(),
            tap(({ value: nodeId }) => {
                this.nodesStore.update({ scrollToNodeId: nodeId });
            })
        );

        const openComments$ = this.urlParamsService.getParamFromURL('comment').pipe(
            first(),
            delay(100),
            map(({ value: commentId }) => commentId),
            switchMap((commentId) =>
                race(
                    // If there is a node param - open comments for node
                    this.urlParamsService.getParamFromURL('node').pipe(
                        map((param) => param.value),
                        tap(async (nodeId) => {
                            await this.openCommentsForNode(nodeId, commentId);
                        })
                    ),

                    // If there is no a node param - open comments for section
                    this.urlParamsService.urlParamNotFound('node').pipe(
                        tap(async () => {
                            await this.openCommentsForSection(commentId);
                        })
                    )
                )
            )
        );

        return merge(scrollToNode$, openComments$);
    }

    private async openCommentsForNode(nodeId: string, commentId: string): Promise<void> {
        const node = this.nodesQuery.getEntity(nodeId) as Node;

        await this.nodeCommentsSidenavService.open(node, commentId);
    }

    private async openCommentsForSection(commentId: string): Promise<void> {
        await this.sectionCommentsSidenav.open(this.section, commentId);
    }

    private onDrop(el, target) {
        let data;

        if (el.dataset.items) {
            data = JSON.parse(el.dataset.items);

            this.nodePresetType = data;

            if (data.length == 1) {
                this.createNode(el, target, data);
            } else {
                this.addTemporaryNode(el, target);
            }
        }
    }

    private addTemporaryNode(el, target) {
        const position = this.getElementIndex(el);
        this.position = position;
        const newData = {
            data: this.nodePresetType,
            pos: position,
            src: this.globalSource,
        };
        this.data.setData(newData);
        const node: Node = {
            id: 'temp-100',
            type: 'undefined',
            position: position - 0.1,
            sectionId: this.sectionId,
            channelSettings: [],
            content: '',
            createdAt: undefined,
            editing: false,
            hasUnresolvedComments: false,
            name: '',
            needsReview: false,
            nodeTypeDiscriminator: '',
            updatedAt: undefined,
        };

        this.nodesStore.add(node);
        el.remove();
    }

    private createNode(el, target, data) {
        const position = this.getElementIndex(el);
        const params = {
            sectionId: this.sectionId,
            type: data[0].type,
        };

        const queryParams = {
            position,
        };

        const content = data[0].content || '';

        const body = {
            content,
        };

        const payload = new NodeDto(params, queryParams, body);
        this.nodesService.createNode(payload).subscribe();

        el.remove();

        if (this.nodesQuery.hasEntity('temp-100')) {
            this.nodesStore.remove('temp-100');
        }
    }

    private onNodeMove(el, target) {
        const position = this.getElementIndex(el);
        const nodeId = el.id;

        const payload = new NodeDto({ nodeId, position });
        this.nodesService.moveNode(payload).subscribe();
    }

    private getElementIndex(el: any) {
        return [].slice.call(el.parentElement.children).indexOf(el);
    }

    /**
     * This displays a browser warning before leaving the page.
     * If any node is selected it might have been modified, and therefore will not be saved.
     */
    @HostListener('window:beforeunload', ['$event'])
    private beforeBrowserTabClosedOrReloaded(e: Event): void {
        if (this.nodesQuery.isAnyInEditingState()) {
            e.preventDefault();
        }
    }

    ngOnDestroy() {
        this.uiService.setToolbarState('overview');
    }
}
