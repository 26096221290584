import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'elias-standard-dialog',
    templateUrl: './standard-dialog.component.html',
    styleUrls: ['./standard-dialog.component.scss'],
})
export class StandardDialogComponent {
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() hideFooter: boolean = false;

    @Output() dialogClosed = new EventEmitter<void>();

    public closeDialog(): void {
        this.dialogClosed.emit();
    }
}
