<elias-shared-multi-step-dialog #stepper linear (cancel)="onCancel()">
    <elias-shared-step
        [isSave]="sectionTypeFormComponent.canSave()"
        (stepIncreased)="visualizeDeletedNodes()"
        class="basic-step"
        title="{{ 'modal.changeSectionType.title' | translate }}"
        subtitle="{{
            'modal.changeSectionType.description'
                | translate : { sectionType: 'section.' + section.type | translate | titlecase }
        }}">
        <elias-form [formConfig]="sectionTypeFormConfig" #sectionTypeFormComponent class="app-form"></elias-form>
    </elias-shared-step>

    <elias-shared-step
        [isSave]="confirmationFormComponent.canSave()"
        (stepIncreased)="changeType()"
        class="basic-step"
        title="{{ 'modal.changeSectionType.title' | translate }}"
        subtitle="{{
            'modal.changeSectionType.sectionChangeMessage'
                | translate : { sectionType: 'section.' + newSectionType | translate | titlecase }
        }}">
        <div class="invalid-nodes-wrapper" [ngClass]="{ 'app-form-hidden': isSaving }">
            <div class="invalid-nodes">
                <div *ngFor="let node of invalidNodes; trackBy: trackNodeById" class="invalid-node">
                    <i class="ei ei-{{ nodeIcons['node'][node.type] }}"></i> {{ node.name }}
                </div>
            </div>
            <p class="invalid-nodes-message">
                {{ 'modal.changeSectionType.invalidNodesMessage' | translate }}
            </p>
            <elias-form [formConfig]="confirmationFormConfig" #confirmationFormComponent class="app-form"></elias-form>
        </div>
        <elias-shared-loading *ngIf="isSaving"></elias-shared-loading>
    </elias-shared-step>

    <elias-shared-step
        (stepIncreased)="navigationAndClose()"
        title="{{ 'modal.changeSectionType.success' | translate }}"
        nextButtonLabel="{{ 'stepper.finish' | translate }}"
        [hideDots]="true"
        [hideBackButton]="true"
        class="basic-step"
        [isSave]="true"
        [isLoading]="false">
        <ng-container *ngIf="!isSaving; else loading">
            <div class="app-form success-animation">
                <ng-lottie class="success" [options]="options"></ng-lottie>
            </div>
        </ng-container>
        <ng-template #loading>
            <elias-shared-loading centered="true"></elias-shared-loading>
        </ng-template>
    </elias-shared-step>
</elias-shared-multi-step-dialog>
