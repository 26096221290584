<elias-confirmation-dialog
    (dialogSubmitted)="onDialogSubmitted()"
    [strictMode]="true"
    buttonLabel="{{ 'actions.delete' | translate }}"
    description="{{ 'modal.delete.section.message' | translate }}"
    errorMessage="{{ 'modal.delete.section.error' | translate }}"
    expectedValue="{{ 'modal.delete.section.delete' | translate }}"
    hint="{{ 'modal.delete.section.hint' | translate }}"
    label="{{ 'modal.delete.section.title' | translate }}"
    title="{{ 'modal.delete.section.title' | translate }}">
</elias-confirmation-dialog>
