import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DomainService } from '../../modules/core/services/domain.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Token } from '../models/token.model';
import { TokenService } from './token.service';
import * as Sentry from '@sentry/angular';
import { resetStores } from '@datorama/akita';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        public dialog: MatDialog,
        private domainService: DomainService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private tokenService: TokenService
    ) {
        this.tokenService.autorefreshToken$.subscribe();
    }

    public login(username: string, password: string): Observable<Token> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        return this.http
            .post<Token>(
                this.domainService.apiBaseUrl + '/token',
                { username, password },
                { headers, withCredentials: true }
            )
            .pipe(
                map((response: Token) => {
                    // If the request ip is whitelisted, a token will be sent with the response. Therefore, 2FA is not required.
                    if (response.token) {
                        this.tokenService.persistToken(response.token);
                    }

                    return response;
                })
            );
    }

    public send2FACode(code: string): Observable<Token> {
        const body = new FormData();
        body.append('_auth_code', code);
        return this.http
            .post<Token>(this.domainService.apiBaseUrl + '/token/2fa-check', body, {
                withCredentials: true,
            })
            .pipe(
                map((response: Token) => {
                    if (response.token) {
                        this.tokenService.persistToken(response.token);
                    }

                    return response;
                })
            );
    }

    public startAgain(): Observable<boolean> {
        const body = new FormData();
        return this.http
            .post(this.domainService.apiBaseUrl + '/token/restart-2fa', body, {
                withCredentials: true,
            })
            .pipe(
                map((response: any) => {
                    return response.status === 200;
                })
            );
    }

    public logout(): void {
        this.dialog.closeAll();
        this.router.navigate(['/logout']).then(() => {
            this.tokenService.removeToken();
            Sentry.configureScope((scope) => scope.setUser(null));

            setTimeout(() => {
                this.router.navigate(['/login']).then(() => {
                    window.location.reload();
                });
            }, 2 * 1000);
        });
    }

    public removeToken(): void {
        this.tokenService.removeToken();
    }

    unattendedLogout(returnUrl: string | null = null): void {
        this.dialog.closeAll();

        this.tokenService.removeToken();
        Sentry.configureScope((scope) => scope.setUser(null));

        this.router.navigate(['/login'], { queryParams: { returnUrl }, relativeTo: this.route }).then(() => {
            resetStores();
        });
    }
}
