import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { CategoriesQuery } from '../../../../shared/state/categories/categories.query';
import { cloneDeep } from 'lodash-es';
import { PublicationGroupService } from '../../../../../publication-group/services/publication-group.service';
import { SortingOption } from '../../../../shared/models/sorting-option.model';
import { QueryParametersBuilder } from '../../../../core/builders/query-parameters.builder';
import { SortingDirection } from '../../../../shared/enums/sorting-direction.enum';
import { UsersQuery } from '../../../../shared/state/users/users.query';
import { FormOptionsFieldConfig } from '../../../models/form-options-field-config.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'elias-form-element-select-publication',
    templateUrl: './form-select-publication.component.html',
    styleUrls: ['./form-select-publication.component.scss'],
})
export class FormSelectPublicationComponent extends BasicFormElementComponent implements OnInit, OnDestroy {
    @Input() settings;

    selectedValue: string;
    filteredOptions: FormOptionsFieldConfig[] = [];
    selectedLocale;
    ObjectKeys = Object.keys;
    genFilter: string[] = [];
    pubTypeFilter: string[] = [];
    stateFilter: string;
    user: any;
    searchTerm = '';
    private searchSubject = new Subject<string>();
    publicationViewOption = 2;

    sort: string | null = null;
    sortDirection: SortingDirection = SortingDirection.Desc;
    page = 1;
    hasNextPage = false;
    loading = false;

    get publicationGroupSorting(): SortingOption {
        return {
            key: this.sort,
            direction: this.sortDirection,
        };
    }

    constructor(
        injector: Injector,
        public categoryQuery: CategoriesQuery,
        private publicationGroupService: PublicationGroupService,
        private usersQuery: UsersQuery
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initValue) {
            this.selectedValue = this.initValue;
        }

        this.formGroup.get(this.name).setValue(this.selectedValue);
        this.user = this.usersQuery.getLoggedInUser();

        this.getPublicationGroups();

        this.searchSubject.pipe(debounceTime(300)).subscribe((searchValue) => {
            this.searchTerm = searchValue;
            this.getPublicationGroups();
        });
    }

    ngOnDestroy() {
        this.searchTerm = '';
        this.searchSubject.complete();
    }

    toggleActivation(option) {
        this.selectedValue = option.key;
        this.formGroup.get(this.name).setValue(this.selectedValue);
    }

    isActive(option) {
        return this.selectedValue === option.key;
    }

    onSearch(value: string) {
        this.searchSubject.next(value);
    }
    trackByIndex(index, item) {
        return index;
    }

    getPublicationGroups(reset = true) {
        if (reset) {
            this.page = 1;
        }

        const queryParametersBuilder = new QueryParametersBuilder();

        queryParametersBuilder.addFilter('locale', this.selectedLocale);
        queryParametersBuilder.addFilter('state', this.stateFilter);
        queryParametersBuilder.addFilter('categories', this.genFilter);
        queryParametersBuilder.addFilter('publicationType', this.pubTypeFilter);

        queryParametersBuilder.addSorting(this.sort, this.sortDirection);
        queryParametersBuilder.addSearch(this.searchTerm);

        queryParametersBuilder.addPage(this.page);

        const options = queryParametersBuilder.getQueryOptions();

        this.loading = true;
        this.publicationGroupService.getPublicationGroupsWithoutUpdatingStore(options).subscribe((result) => {
            const mappedOptions = result['data'].map((publicationGroup) => {
                return {
                    key: publicationGroup.id,
                    value: publicationGroup.name,
                    others: publicationGroup,
                };
            });

            if (reset) {
                this.filteredOptions = mappedOptions;
            } else {
                this.filteredOptions = [...this.filteredOptions, ...mappedOptions];
            }

            this.hasNextPage = result['hasNextPage'];
            this.loading = false;
        });
    }

    public onChangeSortingValue(value: string | null) {
        this.sort = value;
        this.sortDirection = SortingDirection.Desc;

        if (value !== 'createdAt' && value !== 'publicationDate') {
            this.sortDirection = SortingDirection.Asc;
        }

        this.getPublicationGroups();
    }

    onOptionSelected(filterType, value) {
        if (filterType === 'locale') {
            this.selectedLocale = value;
            this.getPublicationGroups();
        }
    }

    onChangeGenCategoryValue(filterType, value) {
        let fv = cloneDeep(this.genFilter);
        if (value == null) {
            fv = [];
        } else {
            const index = this.genFilter.indexOf(value);
            if (index > -1) {
                fv.splice(index, 1);
            } else {
                fv.push(value);
            }
        }
        this.genFilter = fv;
        this.getPublicationGroups();
    }

    public onChangePubTypeCategoryValue(filterType, value) {
        let fv = cloneDeep(this.pubTypeFilter);
        if (value == null) {
            fv = [];
        } else {
            const index = this.pubTypeFilter.indexOf(value);
            if (index > -1) {
                fv.splice(index, 1);
            } else {
                fv.push(value);
            }
        }
        this.pubTypeFilter = fv;
        this.getPublicationGroups();
    }

    public onChangeViewOption(viewOption: number) {
        this.publicationViewOption = viewOption;
    }

    public onChangeFilterValue(stateType, value) {
        this.stateFilter = value;
        this.getPublicationGroups();
    }

    public loadMore() {
        if (!this.hasNextPage) {
            return;
        }

        this.page += 1;
        this.getPublicationGroups(false);
    }
}
