import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { Channel } from '../../../../modules/shared/models/channel.model';
import { ChannelsQuery } from '../../../../modules/shared/state/channels/channels.query';

@Component({
    selector: 'elias-editor-preview-html',
    templateUrl: 'preview-html.component.html',
    styleUrls: ['preview-html.component.scss'],
})
export class PreviewHtmlComponent {
    public contentUrl: SafeResourceUrl;
    public channel$: Observable<Channel | undefined>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private channelsQuery: ChannelsQuery,
        private dialogRef: MatDialogRef<PreviewHtmlComponent>,
        private domSanitizer: DomSanitizer
    ) {
        const url = this.getBlobUrl(this.data['content']);
        this.contentUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);

        this.channel$ = this.channelsQuery.selectEntity(this.data['channelId']);
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    private getBlobUrl(html: string): string {
        const blob = new Blob([html], { type: 'text/html' });
        return URL.createObjectURL(blob);
    }
}
