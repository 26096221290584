<elias-base-dialog (dialogClosed)="closeDialog()">
    <div class="header" header>
        <h1>{{ title }}</h1>
        <p class="subtitle" *ngIf="subtitle" [innerHTML]="subtitle"></p>
    </div>

    <div class="content">
        <ng-content></ng-content>
    </div>

    <footer footer *ngIf="!hideFooter">
        <div class="wrapper">
            <ng-content select="[footer]"></ng-content>
        </div>
    </footer>
</elias-base-dialog>
