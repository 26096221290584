<elias-accent-dialog [title]="title" [showCloseButton]="false" [color]="color">
    <div class="content">
        <div class="message">{{ description }}</div>

        <form class="confirmation-form" [formGroup]="confirmFormGroup" (ngSubmit)="onSubmit()">
            <mat-form-field *ngIf="strictMode" class="confirmation-field">
                <mat-label>{{ label }}</mat-label>
                <input
                    matInput
                    [placeholder]="label"
                    formControlName="confirmation"
                    data-karma="input-field"
                    data-cy="input-confirmation" />

                <mat-error class="error">{{ errorMessage }}</mat-error>
                <mat-hint class="hint">{{ hint }}</mat-hint>
            </mat-form-field>

            <div class="buttons">
                <elias-shared-basic-button *ngIf="cancellable" mat-dialog-close data-karma="cancel-button">
                    {{ 'actions.cancel' | translate }}
                </elias-shared-basic-button>
                <elias-shared-stroked-button
                    [color]="color"
                    [disabled]="confirmFormGroup.invalid"
                    data-cy="confirm-btn"
                    data-karma="confirm-button"
                    type="submit">
                    {{ buttonLabel ?? ('actions.confirm' | translate) }}
                </elias-shared-stroked-button>
            </div>
        </form>
    </div>
</elias-accent-dialog>
