import { Injectable } from '@angular/core';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import { StatusQuery } from '../state/status/status.query';
import { AuthService } from '../../../auth/service/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IncomingMaintenanceComponent } from '../dialogs/incoming-maintenance/incoming-maintenance.component';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceService {
    public incomingMaintenance$: Observable<any>;
    public maintenanceLogout$: Observable<any>;

    private static readonly INCOMING_MAINTENANCE_OFFSET = -2 * 60 * 1000; // 2 minutes
    private static readonly MAINTENANCE_LOGOUT_OFFSET = -2 * 1000; // 2 seconds

    constructor(private dialog: MatDialog, private statusQuery: StatusQuery, private authService: AuthService) {
        this.incomingMaintenance$ = this.statusQuery.maintenanceScheduledFor$.pipe(
            filter((value): value is string => !!value),
            switchMap((scheduledFor) =>
                of(scheduledFor).pipe(
                    delay(this.createDateWithOffset(scheduledFor, MaintenanceService.INCOMING_MAINTENANCE_OFFSET)),
                    takeUntil(timer(this.createDateWithOffset(scheduledFor))),
                    tap(() => {
                        this.dialog.open(IncomingMaintenanceComponent);
                    })
                )
            )
        );

        this.maintenanceLogout$ = this.statusQuery.maintenanceScheduledFor$.pipe(
            filter((value): value is string => !!value),
            switchMap((scheduledFor) =>
                timer(this.createDateWithOffset(scheduledFor, MaintenanceService.MAINTENANCE_LOGOUT_OFFSET))
            ),
            tap(() => {
                if (this.statusQuery.getValue().maintenance.isWhitelisted) {
                    return;
                }

                this.authService.logout();
            })
        );
    }

    private createDateWithOffset(date: string, offset: number = 0): Date {
        return new Date(new Date(date).getTime() + offset);
    }
}
