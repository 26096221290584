<elias-shared-multi-step-dialog (cancel)="onCancel()">
    <elias-shared-step
        title="{{ 'versioning.modal.restore.title' | translate }}"
        subtitle="{{
            'versioning.modal.restore.description'
                | translate : { changeDate: changeDate | localizedDate : 'eliasShortDateTimeSeconds' }
        }}"
        nextButtonLabel="{{ 'actions.restore' | translate }}"
        (stepIncreased)="restoreVersion()"
        [isSave]="true"
        [hideDots]="true">
        <elias-version-diff [diff]="diff" [condensedView]="false"></elias-version-diff>
    </elias-shared-step>
</elias-shared-multi-step-dialog>
