<elias-shared-multi-step-dialog (cancel)="onCancel()">
    <elias-shared-step
        title="{{ 'versioning.modal.changes.title' | translate }}"
        subtitle="{{
            'versioning.modal.changes.description'
                | translate : { changeDate: changeDate | localizedDate : 'eliasShortDateTimeSeconds' }
        }}"
        nextButtonLabel="{{ 'actions.restore' | translate }}..."
        (stepIncreased)="next()"
        [isSave]="!isDiffEmpty"
        [hideDots]="true">
        <elias-version-diff
            *ngIf="!isDiffEmpty; else emptyDiff"
            [diff]="diff"
            [condensedView]="false"></elias-version-diff>
        <ng-template #emptyDiff>
            <elias-shared-placeholder
                [message]="'versioning.modal.changes.empty' | translate"></elias-shared-placeholder>
        </ng-template>
    </elias-shared-step>
</elias-shared-multi-step-dialog>
