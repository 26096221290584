<div [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start space-between">
        <div class="filters" fxFlex="fill" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
            <elias-shared-search-field
                [placeholder]="'assetStorages.filter.search' | translate"
                (searchChange)="applyFilter($event)">
            </elias-shared-search-field>
        </div>
    </div>
    <ng-container *ngIf="filteredOptions.length > 0; else showNoRecord">
        <div fxLayout="column" fxLayoutGap="20px grid" class="styling">
            <ng-container *ngFor="let option of filteredOptions; trackBy: trackByIndex; let i = index">
                <div>
                    <mat-card
                        [ngClass]="{
                            active: isActive(option),
                            selected: isActive(option),
                            disabled: option.disabled
                        }"
                        (click)="toggleActivation(option)"
                        class="storage"
                        matTooltip="{{ 'assetStorages.requestPending' | translate }}"
                        [matTooltipDisabled]="!option.disabled"
                        matTooltipPosition="after">
                        <mat-card-header fxLayout="column">
                            <i *ngIf="isActive(option)" class="ei ei-done done"></i>
                            <i *ngIf="option.disabled" class="ei ei-processing pending"></i>
                            <div fxLayout="row" fxLayoutGap="10px">
                                <i class="ei ei-folder"></i>
                                <mat-card-title>{{ option.value }}</mat-card-title>
                            </div>
                        </mat-card-header>
                    </mat-card>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #showNoRecord>
        <div>{{ 'assetStorages.noRecordError' | translate }}</div>
    </ng-template>
</div>
