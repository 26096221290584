<form novalidate #keyFiguresForm="ngForm" class="form-document" id="keyfigure-editor">
    <div class="e-layout-{{ keyFigureLayout }}" *ngIf="!alignVertically">
        <div class="row">
            <div
                *ngFor="let keyFigureCol of keyFigures; let i = index; trackBy: trackByIndex"
                class="col-{{ getColClass() }}"
                [attr.data-col]="i"
                [dragula]="'keyfigure-draggable'">
                <ng-container *ngFor="let keyFigureItem of keyFigureCol; let k = index; trackBy: trackByIndex">
                    <div [attr.data-index]="k">
                        <div
                            class="pe-element-inner keyfigure-inner e-key-figure"
                            (click)="openEditor(keyFigureItem, i, k)">
                            <i class="ei ei-settings setting-icon"></i>
                            <div class="title" [innerHTML]="keyFigureItem.title"></div>
                            <div class="number" [innerHTML]="keyFigureItem.number"></div>
                            <div class="unit" [innerHTML]="keyFigureItem.unit"></div>
                            <div class="description" [innerHTML]="keyFigureItem.description"></div>
                            <button mat-icon-button class="element-button-drag">
                                <i class="ei ei-drag element-button-drag-icon"></i>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="pe-element-button-container">
                    <elias-shared-flat-button color="accent" (buttonClicked)="addElement(i)">
                        <i class="ei ei-plus-full"></i>{{ 'component.keyfigures.add' | translate }}
                    </elias-shared-flat-button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="alignVertically">
        <div
            class="e-layout-{{ keyFigureLayout }} align-vertically"
            *ngFor="let i of elementIndexes; trackBy: trackByIndex">
            <div class="row">
                <div
                    class="column col-{{ getColClass() }}"
                    *ngFor="let keyFigureCol of keyFigures; let colIndex = index; trackBy: trackByIndex"
                    [attr.data-col]="colIndex"
                    [attr.data-row]="i"
                    [dragula]="'keyfigure-draggable'">
                    <ng-container *ngIf="keyFigureCol.length > i">
                        <div class="data-container" [attr.data-index]="i">
                            <div
                                class="pe-element-inner keyfigure-inner e-key-figure"
                                (click)="openEditor(keyFigureCol[i], colIndex, i)">
                                <i class="ei ei-settings setting-icon"></i>
                                <div class="title" [innerHTML]="keyFigureCol[i]?.title"></div>
                                <div class="number" [innerHTML]="keyFigureCol[i]?.number"></div>
                                <div class="unit" [innerHTML]="keyFigureCol[i]?.unit"></div>
                                <div class="description" [innerHTML]="keyFigureCol[i]?.description"></div>
                                <button mat-icon-button class="element-button-drag">
                                    <i class="ei ei-drag element-button-drag-icon"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="button-container">
            <div class="pe-element-button-container" *ngFor="let i of columnList; trackBy: trackByIndex">
                <elias-shared-flat-button color="accent" (buttonClicked)="addElement(i)">
                    <i class="ei ei-plus-full"></i>{{ 'component.keyfigures.add' | translate }}
                </elias-shared-flat-button>
            </div>
        </div>
    </div>
</form>
