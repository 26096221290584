<div class="section-type-editor">
    <h3 class="title">{{ 'propertyEditor.sectionType' | translate }}</h3>
    <p class="description">{{ 'propertyEditor.changeTypeDescription' | translate }}</p>
    <div>
        <elias-shared-stroked-button
            (buttonClicked)="changeSectionType()"
            [disabled]="disabled"
            data-cy="btn-change-type"
            color="accent">
            {{ 'sectionTypeEditor.changeType' | translate }}
        </elias-shared-stroked-button>
    </div>
</div>
