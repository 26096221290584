import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import { Observable, Subscription } from 'rxjs';
import { Asset } from '../../../../../modules/shared/models/asset.model';
import { ImageGridForm } from '../../../forms/image-grid.form';
import { NodeSubitemEditorComponent } from '../../node-subitem-editor/node-subitem-editor.component';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';
import { AssetBrowserButtonComponent } from '../../../../asset-browser/components/asset-browser-button/asset-browser-button.component';
import { FlashMessageService } from '../../../../../modules/core/services/flash-message.service';
import { FormConfig } from '../../../../../modules/forms/models/form-config.model';
import { NodeType } from '../../../models/node/node-type.model';
import { ImageCroppingService } from '../../../../image-cropping/image-cropping.service';

@Component({
    selector: 'elias-editor-edit-image',
    templateUrl: './edit-image.component.html',
    styleUrls: ['edit-image.component.scss'],
})
export class EditImageComponent implements NodeEdit, AfterViewInit, OnDestroy, OnInit {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: any;
    @Input() nodeViewModel: Observable<any>;

    @Output() contentChange = new EventEmitter<any>();

    id: string;
    aspectRatio: string;
    description: string;
    link: string;
    objectData: { [k: string]: string } = {};
    objectKeys = Object.keys;
    formConfig!: FormConfig;
    order;
    imageData = [];
    itemFieldSettings;

    nodeViewModelSubscription: Subscription;

    @ViewChild('assetBrowserButton') assetBrowserButton: AssetBrowserButtonComponent;

    constructor(
        private flashMessageService: FlashMessageService,
        private imageCroppingService: ImageCroppingService,
        private imageForm: ImageGridForm,
        private sideNavService: SideNavService
    ) {}

    ngOnInit() {
        this.formConfig = this.imageForm.getConfiguration('image');
        const parsedContent = this.content ? JSON.parse(this.content) : {};
        for (const key of Object.keys(parsedContent)) {
            this.objectData[key] = parsedContent[key] || '';
        }
        this.itemFieldSettings = JSON.parse(this.node.itemFieldSettings);
        this.order = Object.keys(JSON.parse(this.node.itemFieldSettings));
        if (this.node.aspectRatio) {
            this.aspectRatio = this.node.aspectRatio;
        } else {
            this.nodeViewModelSubscription = this.nodeViewModel.subscribe((nodeViewModel) => {
                if (nodeViewModel !== null) {
                    this.aspectRatio = nodeViewModel.aspectRatio;
                }
            });
        }
    }

    ngAfterViewInit(): void {
        // auto open asset manager
        if (this.objectData['image'] === undefined) {
            if (this.assetBrowserButton) {
                this.assetBrowserButton.openAssetManager();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.nodeViewModelSubscription) {
            this.nodeViewModelSubscription.unsubscribe();
        }
    }

    onContentChange() {
        const defaultData = {
            image: '',
            description: '',
            link: '',
            title: '',
            subtitle: '',
            source: '',
        };

        const dataWithDefaults = { ...defaultData, ...this.objectData };

        const updatedData = {};

        for (const key in dataWithDefaults) {
            let value = dataWithDefaults[key];

            if (key === 'description' && value) {
                value = value.replace(/"/g, "'");
            }

            if (key === 'link' && value) {
                value = this.validateURL(value);
            }

            updatedData[key] = value;
        }

        this.content = JSON.stringify(updatedData);
        this.contentChange.emit(this.content);
    }

    validateURL(content) {
        if (content.startsWith('https://') || content.startsWith('http://')) return content;
        else return 'https://' + content;
    }

    onAssetsSelected(assets: Asset[]) {
        if (Array.isArray(assets) && assets.length === 1) {
            this.objectData['image'] = assets[0].id;

            if (this.objectData.hasOwnProperty('image') && this.objectData['image'] !== null) {
                this.onContentChange();
            } else {
                this.flashMessageService.showTranslatedError('component.image.selectionError');
            }
        } else {
            this.flashMessageService.showTranslatedError('component.image.selectionError');
        }
    }

    openEditor() {
        const defaultData = { description: '', title: '', subtitle: '', link: '', source: '' };
        this.objectData = { ...defaultData, ...this.objectData };

        const onSave = new EventEmitter<any>();
        const inputs = {
            data: this.objectData,
            type: 'image',
            config: this.formConfig,
            isDelete: false,
        };
        const outputs = {
            save: onSave,
        };

        this.sideNavService.setComponent(NodeSubitemEditorComponent, inputs, outputs);

        onSave.subscribe((data) => {
            this.onModifyData(data);
        });
    }

    onModifyData(data) {
        Object.assign(this.objectData, data);

        this.onContentChange();
        this.close();
    }

    close() {
        this.sideNavService.close();
    }

    getData(val) {
        if (val == undefined || val == '') return '';
        else return val;
    }

    trackByIndex(index, item) {
        return index;
    }

    public openCroppingEditor(assetId: string): void {
        if (!assetId) {
            return;
        }

        this.imageCroppingService.openCroppingEditor(assetId, this.node.id).subscribe((result) => {
            if (!result.replaced) {
                this.objectData['image'] = result.asset.id;
                this.onContentChange();
            }
        });
    }
}
