import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { ID } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { FormOptionsFieldConfig } from '../../../models/form-options-field-config.model';

@Component({
    selector: 'elias-form-element-select-shared-storage',
    templateUrl: './form-select-shared-storage.component.html',
    styleUrls: ['./form-select-shared-storage.component.scss'],
})
export class FormSelectSharedStorageComponent extends BasicFormElementComponent implements OnInit {
    @Input() multiple = false;
    @Input() options: FormOptionsFieldConfig[] = [];
    @Input() settings;

    selectedValue: ID[] = [];
    ObjectKeys = Object.keys;
    filteredOptions: FormOptionsFieldConfig[] = [];

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initValue) {
            this.selectedValue = cloneDeep(this.initValue);
        }
        if (this.settings) {
            if (this.settings['multiple']) {
                this.multiple = true;
            }
        }
        this.filteredOptions = this.options;
        this.formGroup.get(this.name).setValue(this.selectedValue);
    }

    toggleActivation(option: FormOptionsFieldConfig) {
        if (option.disabled) {
            return;
        }

        if (this.multiple) {
            const index = this.selectedValue.indexOf(option.key);
            if (index > -1) {
                this.selectedValue.splice(index, 1);
            } else {
                this.selectedValue.push(option.key);
            }
        } else {
            this.selectedValue = [option.key];
        }

        this.formGroup.get(this.name).setValue(this.selectedValue);
    }

    isActive(option: FormOptionsFieldConfig) {
        if (this.multiple) {
            const index = this.selectedValue.indexOf(option.key);
            return index > -1;
        } else {
            return this.selectedValue.length > 0 && this.selectedValue[0] == option.key;
        }
    }

    trackByIndex(index, item) {
        return index;
    }

    applyFilter(filterValue: string | null): void {
        if (filterValue && filterValue.trim()) {
            this.filteredOptions = this.options.filter(
                (option) => option.value.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
            );
        } else {
            this.filteredOptions = this.options;
        }
    }
}
