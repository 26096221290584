import { Component, Input, OnInit } from '@angular/core';
import { SelectedElement, SelectedNode, SelectedSection } from '../../models/selected-element.model';
import { NodesService } from '../../services';
import { SaveService } from '../../services/save.service';
import { PropertyEditorQuery } from '../../state/property-editor/property-editor.query';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Node } from '../../models/node/node.model';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { SectionsQuery } from '../../state/sections/sections.query';
import { NodeDto } from '../../models/dto/node-dto.model';
import { UiService } from '../../../../modules/core/services/ui.service';
import { cloneDeep } from 'lodash-es';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { FlashMessageService } from '../../../../modules/core/services/flash-message.service';
import { FormConfig } from '../../../../modules/forms/models/form-config.model';
import { AddChannelSettingsForm } from '../../forms/add-channel-settings.form';
import { SelectedElementType } from '../../enums/selected-element-type.enum';
import { Section } from '../../models/section.model';
import { DeleteNodeComponent } from '../../dialogs/delete-node/delete-node.component';

@Component({
    selector: 'elias-editor-property-editor',
    templateUrl: './property-editor.component.html',
    styleUrls: ['./property-editor.component.scss'],
})
export class PropertyEditorComponent implements OnInit {
    @Input() nodeId?: string;
    @Input() sectionId?: string;
    @Input() sectionOrNodeType?: 'section' | 'node';

    public initData: { [k: string]: any } = {};
    public channelSettings: any[] = [];
    public isSaving = false;
    public formConfig: FormConfig = { elements: [] };
    public selectedElement?: SelectedElement;

    constructor(
        public dialog: MatDialog,
        private addChannelSettingsForm: AddChannelSettingsForm,
        private flashMessageService: FlashMessageService,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private propertyEditorQuery: PropertyEditorQuery,
        private saveService: SaveService,
        private sectionsQuery: SectionsQuery,
        private sideNavService: SideNavService,
        private uiService: UiService
    ) {}

    ngOnInit(): void {
        this.uiService.setToolbarState('editor');

        if (!this.sectionOrNodeType) {
            return;
        }

        const state = this.propertyEditorQuery.getValue();

        if (this.sectionOrNodeType === SelectedElementType.Node) {
            const node = this.nodesQuery.getEntity(this.nodeId) as Node;
            this.selectedElement = new SelectedNode(node);

            this.formConfig.elements = state.nodeConfigurations[node.type]?.properties ?? [];
            this.channelSettings = state.nodeConfigurations[node.type]?.channelSettings ?? [];
        } else {
            const section = this.sectionsQuery.getEntity(this.nodeId) as Section;
            this.selectedElement = new SelectedSection(section);

            this.formConfig.elements = state.sectionConfigurations[section.type]?.properties ?? [];
            this.channelSettings = state.sectionConfigurations[section.type]?.channelSettings ?? [];
        }

        const channelSettingsFormConfig = this.addChannelSettingsForm.getConfiguration();
        this.formConfig.elements = this.formConfig.elements.concat(channelSettingsFormConfig.elements);

        this.setInitData();
    }

    public onFormSubmitted(props: any): void {
        const properties = cloneDeep(props);
        this.isSaving = true;

        if (properties.channelSettings) {
            const newChannelSettings: any = {};
            properties.channelSettings.map((channelSetting: any) => {
                newChannelSettings[channelSetting.name] = { active: channelSetting.active };
            });
            properties.channelSettings = newChannelSettings;
        }

        if (!this.selectedElement) {
            return;
        }

        this.saveService.saveProperties(this.selectedElement, properties).subscribe(() => {
            this.sideNavService.close();
        });
    }

    public isSelectedSection(element?: SelectedElement): element is SelectedSection {
        return element?.type === 'section';
    }

    public isSelectedNode(element?: SelectedElement): element is SelectedNode {
        return element?.type === 'node';
    }

    public removeNode(): void {
        const modalRef = this.dialog.open(DeleteNodeComponent);

        modalRef.afterClosed().subscribe((remove) => {
            if (remove) {
                const payload: NodeDto = new NodeDto({ nodeId: this.nodeId });
                this.nodesService.deleteNode(payload).subscribe(
                    () => {
                        this.flashMessageService.showTranslatedInfo('modal.delete.node.success');
                    },
                    (err) => {
                        this.flashMessageService.showTranslatedError('propertyEditor.remove.error');
                    }
                );
                this.close();
            }
        });
    }

    public copyNode(): void {
        if (!this.nodeId) {
            return;
        }

        this.nodesService.copyNode(this.nodeId).subscribe();
        this.close();
    }

    public close(): void {
        this.sideNavService.close();
    }

    private setInitData(): void {
        const data: { [k: string]: any } = {};
        this.formConfig.elements.map((property) => {
            if (property.name && this.selectedElement?.element.hasOwnProperty(property.name)) {
                data[property.name] = (this.selectedElement.element as any)[property.name];
            }
        });

        data['channelSettings'] = this.channelSettings.map((channelSetting) => {
            return {
                name: channelSetting.name,
                label: channelSetting.label,
                active: this.selectedElement?.element.channelSettings[channelSetting.name]?.active,
            };
        });

        this.initData = data;
    }
}
