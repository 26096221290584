<div
    class="content"
    [ngClass]="{
        'content-collapsed': collapsed && offsetHeight > contentHeight
    }"
    [style.max-height]="collapsed ? contentHeight + 'px' : offsetHeight + 'px'">
    <div #contentElement class="inner-content">
        <ng-content></ng-content>
    </div>
</div>
<div class="button-container">
    <elias-shared-basic-button
        *ngIf="collapsed"
        (buttonClicked)="toggleCollapse($event)"
        [ngStyle]="{ display: offsetHeight > contentHeight ? 'block' : 'none' }"
        class="see-more-button"
        size="small">
        {{ 'shared.collapsibleContent.seeMore' | translate }}
    </elias-shared-basic-button>
    <elias-shared-basic-button
        *ngIf="!collapsed"
        (buttonClicked)="toggleCollapse($event)"
        class="see-less-button"
        size="small">
        {{ 'shared.collapsibleContent.seeLess' | translate }}
    </elias-shared-basic-button>
</div>
