<div class="body">
    <h1>Worum geht es in dieser Erklärung?</h1>
    <p>
        Datenschutz und Datensicherheit erfordert Vertrauen und Transparenz. Deshalb informieren wir Sie in dieser
        Datenschutzerklärung, wie und wozu wir Ihre Personendaten erheben, bearbeiten und verwenden.
    </p>
    <p>Sie erfahren in dieser Datenschutzerklärung unter anderem:</p>
    <ul>
        <li>welche Personendaten wir erheben und bearbeiten;</li>
        <li>zu welchen Zwecken wir Ihre Personendaten verwenden;</li>
        <li>wer Zugang zu Ihren Personendaten hat;</li>
        <li>welchen Nutzen unsere Datenbearbeitung für Sie hat;</li>
        <li>wie lange wir Ihre Personendaten bearbeiten;</li>
        <li>welche Rechte Sie mit Bezug auf Ihre Personendaten haben; und</li>
        <li>wie Sie uns kontaktieren können.<br /></li>
    </ul>
    <p>
        Wir haben diese Datenschutzerklärung sowohl am Schweizer Datenschutzgesetz als auch an der Europäischen
        Datenschutz-Grundverordnung ausgerichtet. Die DSGVO hat sich weltweit als Massstab für einen starken Datenschutz
        etabliert. Ob und inwieweit die DSGVO anwendbar ist, hängt aber vom Einzelfall ab.
    </p>
    <h1>Wer sind wir?</h1>
    <p>
        eliasPublisher ist ein innovatives Redaktions- und Publikationsystem, welches von der LST LST AG,
        Täschmattstrasse 19, 6015 Luzern konzipiert, entwickelt und betrieben wird. Die LST AG ist zudem auf die
        Erbringung von Dienstleistungen, in den Bereichen Marketingberatung, Marketingkommunikation,
        Kommunikationsdesign, Webdesign und Entwicklung von Kommunikationslösungen im Onlinebereich spezialisiert.
        Weitere Informationen zur LST AGfinden Sie auf unserer Website www.lst.team
    </p>
    <h1>Wer ist für die Datenbearbeitung verantwortlich?</h1>
    <p>Datenschutzrechtlich verantwortlich für eine bestimmte Datenbearbeitung ist die LST AG.</p>
    <h1>Für wen und wofür ist diese Datenschutzerklärung bestimmt?</h1>
    <p>
        Diese Datenschutzerklärung gilt für alle Nutzer und Nutzerinnen von eliasPublisher. Diese Datenschutzerklärung
        ist sowohl auf die Bearbeitung von bereits erhobenen als auch von zukünftig erhobenen Personendaten anwendbar.
    </p>
    <h1>Welche Personendaten bearbeiten wir?</h1>
    <p>
        Personendaten sind Informationen, die mit einer bestimmten Person in Verbindung gebracht werden können. Wir
        bearbeiten verschiedene Kategorien solcher Personendaten. Die wichtigsten Kategorien finden Sie zu Ihrer
        Orientierung nachstehend. Im Einzelfall können wir aber auch weitere Personendaten bearbeiten. Folgend erfahren
        Sie mehr darüber, woher diese Daten kommen und zu welchen Zwecken wir diese bearbeiten.
    </p>
    <h2>Identifikationsdaten und Kontaktdaten</h2>
    <p>
        Wir erfassen grundlegende Personendaten der Nutzer und Nutzerinnen von eliasPublisher, Kunden von eliasPublisher
        und deren Mitarbeitenden. Wir erheben diese insbesondere im Rahmen der Verwaltung von
        eliasPublisher-Benutzerkonten. Diese Identifikationsdaten und Kontaktdaten beinhalten zum Beispiel:
    </p>
    <ul>
        <li>Vorname, Name, Geschlecht;</li>
        <li>E-Mail-Adresse, Telefonnummer und andere Kontaktdaten;</li>
        <li>Angaben zu Ihrem Arbeitgeber und Ihrer Position sowie Funktion im Unternehmen;</li>
        <li>Kundenbuchhaltungsdaten (bspw. Bankverbindungen, Bonität oder andere);</li>
        <li>Angaben zu verbundenen Webseiten, Social Media-Profilen etc;</li>
        <li>Angaben zu Sprachpräferenzen, Zivilstand, Nationalität, etc;</li>
        <li>Einstellungen betreffend dem Erhalt von Informationen und Werbung;</li>
        <li>Angaben zu Ihrem Status bei uns (bsp. Inaktivität);</li>
        <li>Datum und Zeit von Registrierungen.</li>
    </ul>
    <h2>Nutzungsdaten</h2>
    <p>
        Auf Basis von technischen Daten können wir insbesondere auch Verhaltensdaten sogenannte Nutzungsdaten ableiten,
        also beispielsweise Angaben zu Ihrer Nutzung von eliasPublisher. Nutzungsdaten enthalten zum Beispiel:
    </p>
    <ul>
        <li>Login-Daten auf eliasPublisher;</li>
        <li>Server-Log Dateien;</li>
        <li>Alle Interaktionen mit eliasPublisher;</li>
        <li>Gespeicherte Cookies im Browser;</li>
        <li>Verweildauer auf unseren Systemen und Websites.</li>
    </ul>
    <h3>Technische Daten</h3>
    <p>
        Wenn Sie eliasPublisher nutzen, erheben wir bestimmte technische Daten. Zu den technischen Daten gehören auch
        die Protokolle, in denen wir die Nutzung unserer Systeme aufzeichnen, damit wir die Sicherheit der Systeme
        gewährleisten können. Zum Teil können wir Ihrem Endgerät auch eine eindeutige Kennnummer zuweisen, z.B. mittels
        Cookies oder ähnlichen Technologien, damit wir es wiedererkennen können. Solche technischen Technische Daten
        enthalten zum Beispiel:<br />
    </p>
    <ul>
        <li>IP-Adressen des verwendeten Endgerät;</li>
        <li>Betriebssystem und Bildschirmauflösung des verwendeten Endgerät;</li>
        <li>Browser-Typ und -Version des verwendeten Endgerät;</li>
        <li>Standortdaten des verwendeten Endgerät;</li>
        <li>Geräte-ID.</li>
    </ul>
    <h3>Transaktionsdaten</h3>
    <p>
        Wenn Sie mit uns oder wir mit Ihnen in Kontakt stehen oder eine Supportanfrage stellen, bearbeiten wir die
        ausgetauschten Kommunikationsinhalte und Angaben über Art, Zeitpunkt und Ort der Kommunikation.
        Transaktionsdaten sind zum Beispiel: <br />
    </p>
    <ul>
        <li>Alle Korrespondenz mit der LST inkl. Angaben zu Art, Zeit und Grund der Kommunikation;</li>
        <li>
            Inhalt von E-Mails, schriftlicher Korrespondenz, Chatnachrichten, Social Media-Mitteilungen,
            Telefongesprächen, Videokonferenzen etc;
        </li>
        <li>Verträge und Vertragsdaten, Rechnungen.</li>
    </ul>
    <h3>Vertragsdaten</h3>
    <p>
        Bei Vertragsdaten handelt sich um Personendaten, welche LST zur Erfüllung eingegangener vertraglicher
        Verpflichtungen erhebt und bearbeitet. Vertragsdaten sind Personendaten, die im Zusammenhang mit dem
        Vertragsschluss bzw. der Vertragserfüllung anfallen, z.B. Angaben zum Vertragsschluss, vertragliche Rechte und
        Pflichten sowie Kontaktdaten. Verträge schliessen wir vor allem mit Kundinnen und Kunden, Geschäftspartnern und
        Dienstleistern/Service Providern bzw. von deren Kontaktpersonen, wenn es sich um juristische Personen handelt.
        Vertragsdaten erhalten zum Beispiel:
    </p>
    <ul>
        <li>
            Angaben über die Abwicklung und Verwaltung von Verträgen (z.B. Kontaktangaben, Lieferadressen, erfolgte oder
            fehlgeschlagene Lieferungen und Zahlungsmittelinformationen);
        </li>
        <li>Daten im Zusammenhang mit dem Kundendienst und der Unterstützung bei technischen Angelegenheiten;</li>
        <li>Daten über unsere Interaktionen mit Ihnen (allenfalls eine Historie mit entsprechenden Eintragungen);</li>
        <li>Angaben zu Mängeln und Beschwerden sowie Anpassungen eines Vertrags;</li>
        <li>
            Daten über die Anbahnung und den Abschluss von Verträgen, z.B. Datum des Vertragsschlusses, Angaben aus dem
            Antragsprozess und Angaben über den betreffenden Vertrag (z.B. Art und Dauer oder falls notwendig
            Identitätsnachweise wie z.B. Kopien amtlicher Ausweise);
        </li>
        <li>Angaben zu finanziellen Belangen (bspw. Rechnungsstellung);</li>
        <li>zu Interaktionen mit Ihnen als Kontaktperson oder Vertreter eines Geschäftspartners; sowie</li>
        <li>
            im Zusammenhang mit Sicherheitsprüfungen und anderen Prüfungen im Hinblick auf die Aufnahme oder Fortsetzung
            einer Geschäftsbeziehung.
        </li>
    </ul>
    <h1>Woher stammen die Personendaten?</h1>
    <h2><strong>Überlassene Personendaten</strong></h2>
    <p>
        Ihre Personendaten geben Sie uns häufig selbst bekannt, beispielsweise wenn Sie sich für eine
        Zweifaktorenauthentifizierung entscheiden, eine Supportanfrage stellen oder mit uns kommunizieren. Insbesondere
        Kundendaten, Vertragsdaten und Kommunikationsdaten geben Sie uns in der überwiegenden Mehrheit der Fälle selbst
        bekannt.
    </p>
    <p>
        Ihre Bekanntgabe von Personendaten erfolgt in der Regel freiwillig, d.h. Sie sind grundsätzlich nicht
        verpflichtet, uns Personendaten bekannt zu geben. Es gibt allerdings Personendaten, welche wir erheben und
        bearbeiten müssen, um die Sicherheit und Funktionalität von eliasPublisher zu gewährleisten und vertragliche
        Verpflichtungen erfüllen zu können, insbesondere im Rahmen gesetzlicher Bestimmungen. Andernfalls können wir
        Dienstleistungen nicht erbringen bzw. vertragliche Verpflichtungen nicht eingehen oder erfüllen.
    </p>
    <p>
        Wenn Sie uns Daten über Drittpersonen (z.B. Arbeitskollegen/Mitarbeitende) übermitteln, gehen wir davon aus,
        dass Sie dazu befugt sind und diese Daten richtig sind. Bitte stellen Sie sicher, dass diese Personen über diese
        Datenschutzerklärung informiert wurden.
    </p>
    <h2>Erhobene Daten</h2>
    <p>
        Wir können Personendaten über Sie auch selbst oder automatisiert erheben, z.B. wenn Sie unsere Webseiten oder
        eliasPublisher aufrufen oder auf einen Link in einem unserer Newsletter klicken. Es handelt sich dabei häufig
        überwiegend um technische Daten.
    </p>
    <h2>Erhaltene Daten</h2>
    <p>
        Personendaten können wir auch von anderen Dritten erhalten, z.B. von Geschäftspartnern, Arbeitgebern oder
        anderen Auftraggebern. Wir können zum Beispiel in folgenden Fällen Ihre Personendaten von Dritten erhalten:
    </p>
    <ul>
        <li>von Ihrem Arbeitgeber, wenn Sie für ein Unternehmen oder eine Behörde tätig sind;</li>
        <li>von Dritten, wenn Korrespondenz und Besprechungen Sie betreffen;</li>
        <li>von Anbietern von Online-Diensten, z.B. Anbietern von Internet-Analysediensten;</li>
        <li>Anbieter von Cyber-Security Dienstleistungen</li>
        <li>von Informationsdiensten zur Einhaltung gesetzlicher Vorgaben wie etwa der Geldwäschereibekämpfung;</li>
        <li>
            von Behörden, Parteien und anderen Dritten im Zusammenhang mit behördlichen und gerichtlichen Verfahren;
        </li>
        <li>
            aus öffentlichen Registern wie z.B. dem Betreibungs- oder Handelsregister, von öffentlichen Stellen wie z.B.
            dem Bundesamt für Statistik, aus den Medien oder aus dem Internet.
        </li>
    </ul>
    <h1>Zu welchem Zweck bearbeiten wir die Personendaten?</h1>
    <p></p>
    <h3>Kommunikation</h3>
    <p>
        Wir möchten mit Ihnen in Kontakt bleiben, Ihnen die neusten Entwicklungen zeigen, Ihnen Tipps und Tricks für
        eliasPublisher zeigen und auf Ihre individuellen Anliegen eingehen. Wir bearbeiten Personendaten deshalb für die
        Kommunikation mit Ihnen, z.B. die Beantwortung von Anfragen und die Kundenpflege. Hierzu verwenden wir
        insbesondere Kundendaten und, soweit die Kommunikation einen Vertrag betrifft, auch Vertragsdaten.
    </p>
    <h3>Abschluss und Erfüllung vertraglicher Verpflichtungen</h3>
    <p>
        Im Zusammenhang mit sich anbahnenden, bestehenden oder beendeten Vertragsbeziehungen bearbeiten wir
        Personendaten. Betroffen sind hauptsächlich Vertragsdaten und Kundendaten. 
    </p>
    <h3>Marketing und Informationen</h3>
    <p>
        Wir möchten Ihnen Neuigkeiten mitteilen und Ihnen gegebenenfalls konkrete Angebote unterbreiten. Aus diesem
        Grund bearbeiten Personendaten zur Beziehungspflege und für Marketingzwecke, z.B. um Ihnen schriftliche und
        elektronische Mitteilungen und Angebote zuzustellen und um Promotionen durchzuführen.
    </p>
    <p>
        Mitteilungen und Informationen können basierend auf ihren Interessensangaben personalisiert werden, um Ihnen
        möglichst nur Informationen zu übermitteln, die für sie voraussichtlich interessant sind. Hierzu verwenden wir
        insbesondere Kunden-, Vertrags-, Kommunikationsdaten. Dabei kann es sich z.B. um folgende Mitteilungen und
        Informationen handeln:
    </p>
    <ul>
        <li>Direkte Kontaktaufnahmen durch Mitarbeitende der LST AG;</li>
        <li>Newsletter, E-Mails und andere elektronische Nachrichten;</li>
        <li>Werbebroschüren, Magazine und andere Drucksachen; sowie</li>
        <li>Einladungen zu Anlässen.</li>
    </ul>
    <p>
        Wir können dafür die Kontaktdaten verwenden, die Sie uns bekanntgeben. Sie können Kontakte zu Marketingzwecken
        jederzeit ablehnen. Bei Newslettern und anderen elektronischen Mitteilungen können Sie sich über einen in der
        Mitteilung integrierten Abmeldelink vom entsprechenden Service abmelden.
    </p>
    <h3>Marktauswertungen und -analysen</h3>
    <p>
        Wir möchten unsere Dienstleistungen laufend verbessern und auf Ihre Bedürfnisse reagieren können. Wir bearbeiten
        Personendaten deshalb im Rahmen von Applikationsanalysen. Dazu bearbeiten wir insbesondere Kundendaten sowie
        technische Daten. Soweit möglich, verwenden wir für diese Zwecke pseudonymisierte oder anonymisierte Angaben. Im
        Rahmen von Marktauswertungen und -analysen können wir zum Beispiel die folgenden Bearbeitungen durchführen:
    </p>
    <ul>
        <li>Durchführungen von Kundenbefragungen, Umfragen und Studien;</li>
        <li>Optimierungen und Verbesserungen der Nutzerfreundlichkeit von eliasPublisher;</li>
        <li>statistische Auswertungen;</li>
    </ul>
    <h3>Sicherheit</h3>
    <p>
        Wir möchten die bestmögliche Sicherheit gewährleisten. Wir bearbeiten Personendaten deshalb auch zu
        Sicherheitszwecken, insbesondere zur Gewährleistung der IT-Sicherheit und zu Beweiszwecken. Dies kann
        grundsätzlich sämtliche Datenkategorien umfassen, insbesondere aber Bild- und Tonaufnahmen sowie technische
        Daten. Diese Daten können wir für die genannten Zwecke erfassen, auswerten und speichern.
    </p>
    <p>Zur Gewährleistung der Sicherheit können wir beispielsweise die folgenden Bearbeitungen durchführen:</p>
    <ul>
        <li>die Auswertung von systemseitigen Aufzeichnungen der Nutzung unserer Systeme (Log-Daten);</li>
        <li>das Vorbeugen, Abwehren und Aufklären von Cyberangriffen und Malware-Attacken;</li>
        <li>Analysen und Tests unserer Netzwerke und IT-Infrastrukturen sowie System- und Fehlerprüfungen;</li>
        <li>Kontrolle der Zugänge zu elektronischen Systemen (z.B. Logins bei Benutzerkonten);</li>
        <li>physische Zugangskontrollen (z.B. Zutritte zu Büroräumlichkeiten); sowie</li>
        <li>Dokumentationszwecke und Anlegen von Sicherheitskopien.</li>
    </ul>
    <h3>Einhaltung von Rechtsvorschriften</h3>
    <p>
        Wir möchten die Voraussetzungen zur Einhaltung rechtlicher Anforderungen schaffen. Wir bearbeiten Personendaten
        deshalb auch, um rechtliche Pflichten einzuhalten und Verstösse zu verhindern und aufzudecken. Dies kann
        grundsätzlich alle genannten Personendatenkategorien betreffen. Zwecks Einhaltung der Rechtsvorschriften können
        wir beispielsweise die folgenden Bearbeitungen durchführen:
    </p>
    <ul>
        <li>Abklärungen über Geschäftspartner;</li>
        <li>die Entgegennahme und Bearbeitung von Beschwerden und anderen Meldungen;</li>
        <li>die gesetzlich vorgeschriebene Aufbewahrung von Randdaten aus dem Fernmeldeverkehr (Mobile-Abo);</li>
        <li>die Durchführung interner Untersuchungen;</li>
        <li>die Sicherstellung der Compliance und das Risikomanagement;</li>
        <li>
            die Offenlegung von Informationen und Unterlagen an Behörden, wenn wir dazu einen sachlichen Grund haben
            oder rechtlich dazu verpflichtet;
        </li>
        <li>die Mitwirkung bei externen Untersuchungen z.B. durch eine Strafverfolgungs- oder Aufsichtsbehörde;</li>
        <li>die Gewährleistung der rechtlich gebotenen Datensicherheit;</li>
        <li>
            die Erfüllung von Auskunfts-, Informations- oder Meldepflichten z.B. im Zusammenhang mit aufsichts- und
            steuerrechtlichen Pflichten, z.B. bei Archivierungspflichten und zur Verhinderung, Aufdeckung und Abklärung
            von Straftaten und anderen Verstössen; sowie
        </li>
        <li>die gesetzlich geregelte Bekämpfung der Geldwäscherei und der Terrorismusfinanzierung.</li>
    </ul>
    <p>
        In allen Fällen kann es sich um Schweizer Recht, aber auch ausländische Bestimmungen handeln, denen wir
        unterstehen, ebenso Selbstregulierungen, Branchen- und andere Standards, die eigene «Corporate Governance» oder
        behördliche Anweisungen.
    </p>
    <h3>Wahrung unserer Rechte</h3>
    <p>
        Wir möchten in der Lage sein, unsere Ansprüche durchzusetzen und uns gegen Ansprüche anderer zu verteidigen. Wir
        bearbeiten Personendaten deshalb auch zur Rechtswahrung, z.B. um Ansprüche gerichtlich, vor- oder
        aussergerichtlich und vor Behörden im In- und Ausland durchzusetzen oder uns gegen Ansprüche zu verteidigen.
        Dabei bearbeiten wir je nach Konstellation unterschiedliche Personendaten, insbesondere Kundendaten sowie
        Vertragsdaten. Zwecks Wahrung unserer Rechte können wir beispielsweise die folgenden Bearbeitungen durchführen:
    </p>
    <ul>
        <li>
            die Abklärung und Durchsetzung unserer Ansprüche, wozu auch Ansprüche mit uns verbundener Unternehmen und
            unserer Vertrags- und Geschäftspartner gehören können;
        </li>
        <li>
            die Abwehr von Ansprüchen gegen uns, unsere Mitarbeitenden, mit uns verbundene Unternehmen und gegen unsere
            Vertrags- und Geschäftspartner;
        </li>
        <li>die Abklärung von Prozessaussichten und anderer rechtlicher, wirtschaftlicher und sonstiger Fragen;</li>
        <li>
            die Teilnahme an Verfahren vor Gerichten und Behörden im In- und Ausland. Beispielsweise können wir Beweise
            sichern, Prozessaussichten abklären lassen oder bei einer Behörde Unterlagen einreichen. Es kann auch sein,
            dass Behörden uns auffordern, Unterlagen und Datenträger offenzulegen, die Personendaten enthalten
        </li>
    </ul>
    <h3>Administrative Unterstützung innerhalb der Firma</h3>
    <p>
        Wir möchten unsere internen Abläufe effizient gestalten. Wir bearbeiten Personendaten deshalb auch für die
        firmeninterne Administration. Dazu bearbeiten wir insbesondere Kunden-, Vertrags und Kommunikationsdaten sowie
        technische Daten. Zur Administration können beispielsweise folgende Bearbeitungen gehören:
    </p>
    <ul>
        <li>die Verwaltung der IT, Risikomanagement, Compliance;</li>
        <li>die Buchhaltung;</li>
        <li>die Archivierung von Daten und die Bewirtschaftung unserer Archive;</li>
        <li>die zentrale Speicherung und Bewirtschaftung von Daten,</li>
        <li>
            die Weiterleitung von Anfragen an die zuständigen Stellen; sowie generell die Prüfung und Verbesserung
            interner Abläufe.
        </li>
    </ul>
    <h1>Gestützt auf welche Rechtsgrundlagen bearbeiten wir Personendaten?</h1>
    <p>
        Je nach Zweck der Datenbearbeitung stützt sich unsere Bearbeitung von Personendaten auf unterschiedliche
        Rechtsgrundlagen. Wir können Personendaten insbesondere dann bearbeiten, wenn die Bearbeitung:
    </p>
    <ul>
        <li>für die Erfüllung eines Vertrags mit Ihnen oder für vorvertragliche Massnahmen erforderlich ist;</li>
        <li>
            für die Wahrnehmung berechtigter Interessen erforderlich ist, beispielsweise wenn Datenbearbeitungen
            zentraler Bestandteil unserer Geschäftstätigkeit sind;
        </li>
        <li>auf einer Einwilligung beruht;</li>
        <li>zur Einhaltung von in- oder ausländischen Rechtsvorschriften erforderlich ist.</li>
    </ul>
    <p>
        Ein berechtigtes Interesse haben wir insbesondere an der Bearbeitung zu den vorstehend beschriebenen Zwecken und
        an der Bekanntgabe von Daten sowie den damit jeweils verbundenen Zielen. Zu den berechtigten Interessen gehören
        jeweils unsere eigenen Interessen und Interessen Dritter.
    </p>
    <p>Diese berechtigten Interessen umfassen z.B. das Interesse</p>
    <ul>
        <li>
            an einer guten Kundenbetreuung, der Kontaktpflege und der Kommunikation mit Kund:innen auch ausserhalb eines
            Vertrags;
        </li>
        <li>an Werbe- und Marketingaktivitäten;</li>
        <li>unsere Kundinnen und Kunden, andere Personen besser kennenzulernen;</li>
        <li>Produkte und Dienstleistungen zu verbessern und neue zu entwickeln;</li>
        <li>
            an der gruppeninternen Verwaltung und dem gruppeninternen Verkehr, der bei einem Konzern mit arbeitsteiliger
            Zusammenarbeit notwendig ist;
        </li>
        <li>an der gegenseitigen Unterstützung der Gruppenunternehmen in ihren Tätigkeiten und Zielen;</li>
        <li>an Compliance und Risikomanagement sowie an der Betrugsbekämpfung;</li>
        <li>am Schutz von Kunden und Kundinnen, anderen Personen und Daten, Geheimnisse;</li>
        <li>
            an der Gewährleistung der IT-Sicherheit, besonders im Zusammenhang mit der Verwendung von Webseiten und
            anderer IT-Infrastruktur;
        </li>
        <li>
            an der Gewährleistung und Organisation des Geschäftsbetriebs, einschliesslich des Betriebs und der
            Weiterentwicklung von Webseiten und anderer Systeme;
        </li>
        <li>an der Unternehmensführung und -entwicklung;</li>
        <li>an der Einhaltung des schweizerischen und ausländischen Rechts sowie interner Regeln.</li>
    </ul>
    <h1>Wem geben wir Personendaten weiter?</h1>
    <h2>Innerhalb der LST AG</h2>
    <p>
        Wir können Personendaten, die wir von Ihnen oder aus Drittquellen erhalten innerhalb der Firma weitergeben. Eine
        Weitergabe kann der Administration oder der Unterstützung der betreffenden Stellen und Ihrer eigenen
        Bearbeitungszwecke dienen, etwa dann, wenn wir die Personalisierung von Marketingaktivitäten, die Entwicklung
        und Verbesserung von Produkten und Dienstleistungen oder Bemühungen zur Erhöhung der allgemeinen Sicherheit
        unterstützen. Die erhaltenen Personendaten können von den betreffenden Personen ggf. auch mit vorhandenen
        Personendaten abgeglichen und verknüpft werden.
    </p>
    <h2>Ausserhalb der LST AG</h2>
    <p>
        Wir können Ihre Personendaten an Unternehmen ausserhalb der LST weitergeben, wenn wir deren Dienstleistungen in
        Anspruch nehmen. In der Regel bearbeiten diese Dienstleister Personendaten in unserem Auftrag als sog.
        «Auftragsbearbeiter». Unsere Auftragsbearbeiter sind verpflichtet, Personendaten ausschliesslich nach unseren
        Instruktionen zu bearbeiten sowie geeignete Massnahmen zur Datensicherheit zu treffen.
    </p>
    <p>Dabei geht es um folgende Auftragsbearbeiter:</p>
    <ul>
        <li>
            Amazon Web Service (Schweiz)<br />Alle Daten und Dateien, welche Sie auf eliasPublisher speichern und
            einpflegen, werden regelmässig auf externen Datenspeichern gesichert. Die Daten werden auf den Systemen des
            Auftragnehmers verschlüsselt und auf Amazon-S3-Speicher abgelegt. Der Schlüssel liegt nur in der Hand des
            Auftragnehmers und ist dadurch dem Zugriff ausländischer Behörden entzogen.
        </li>
        <li>Sentry (USA)<br />Sentry ist ein Anbieter für Fehleranalyse und -auswertung.</li>
        <li>
            SMTP Relay auf Server von metanet.ch (Schweiz)<br />Wird benutzt um den 2. Faktor der 2FA per Email zu
            verschicken: Dieser Dienst erhält Ihre Email-Adresse.
        </li>
        <li>
            SMS-Service von ecall.ch (Schweiz)<br />Wird benutzt, um den 2. Faktor der 2FA per SMS zu verschicken:
            Dieser Dienst erhält die Nummer Ihres Mobil-Telefons.
        </li>
    </ul>
    <p>
        Es ist zudem möglich, dass wir Personendaten an andere Dritte auch zu deren eigenen Zwecken weitergeben, z.B.
        wenn Sie uns Ihre Einwilligung gegeben haben oder wenn wir zu einer Weitergabe gesetzlich verpflichtet oder
        berechtigt sind. In diesen Fällen ist der Empfänger der Daten datenschutzrechtlich ein eigener Verantwortlicher.
    </p>
    <p>Dazu gehören z.B. die folgenden Fälle:</p>
    <ul>
        <li>
            die Offenlegung von Personendaten an Gerichte und Behörden in der Schweiz und im Ausland, z.B. an
            Strafverfolgungsbehörden bei Verdacht auf strafbare Handlungen;
        </li>
        <li>
            die Bearbeitung von Personendaten, um einer gerichtlichen Verfügung oder behördlichen Anordnung nachzukommen
            oder Rechtsansprüche geltend zu machen bzw. abzuwehren oder wenn wir es aus anderen rechtlichen Gründen für
            notwendig halten. Dabei können wir Personendaten auch anderen Verfahrensbeteiligten bekanntgeben.
        </li>
    </ul>
    <p>
        Bitte beachten Sie auch unsere Cookie-Information zur selbständigen Datenerhebung durch Drittanbieter, deren
        Tools wir auf unseren Webseiten und Apps eingebunden haben.
    </p>
    <h1>Wem geben wir Personendaten ins Ausland bekannt?</h1>
    <p>
        Wir bearbeiten und speichern Personendaten meist in der Schweiz und im Europäischen Wirtschaftsraum (EWR). In
        bestimmten Fällen können wir Personendaten aber auch Dienstleistern und sonstigen Empfängern bekanntgeben, die
        sich ausserhalb dieses Gebiets befinden oder Personendaten ausserhalb dieses Gebiets bearbeiten bzw. durch ihre
        eigenen Dienstleister bearbeiten lassen, grundsätzlich in jedem Land der Welt. Die betreffenden Länder verfügen
        möglicherweise nicht über Gesetze, die Ihre Personendaten im gleichen Umfang wie in der Schweiz oder im EWR
        schützen. Sofern wir Ihre Personendaten in einen solchen Staat übermitteln, stellen wir den Schutz Ihrer
        Personendaten in angemessener Weise sicher.
    </p>
    <p>
        Ein Mittel zur Sicherstellung eines angemessenen Datenschutzes ist z.B. der Abschluss von
        Datenübermittlungsverträgen mit den Empfängern Ihrer Personendaten in Drittstaaten, die den erforderlichen
        Datenschutz sicherstellen. Dazu gehören Verträge, die von der Europäischen Kommission und dem Eidgenössischen
        Datenschutz- und Öffentlichkeitsbeauftragten genehmigt, ausgestellt oder anerkannt wurden, sogenannte
        Standardvertragsklauseln. Bitte beachten Sie, dass solche vertraglichen Vorkehrungen einen schwächeren oder
        fehlenden gesetzlichen Schutz teilweise ausgleichen, aber nicht alle Risiken vollständig ausschliessen können
        (z.B. von staatlichen Zugriffen im Ausland). In Ausnahmefällen kann die Übermittlung in Länder ohne angemessenen
        Schutz auch in anderen Fällen zulässig sein, z.B. gestützt auf eine Einwilligung, im Zusammenhang mit einem
        Rechtsverfahren im Ausland oder wenn die Übermittlung für die Abwicklung eines Vertrags erforderlich ist.
    </p>
    <h1>Wie schützen wir Personendaten?</h1>
    <p>
        Wir treffen angemessene Sicherheitsmassnahmen technischer und organisatorischer Natur, um die Sicherheit Ihrer
        Personendaten zu wahren, um sie gegen unberechtigte oder unrechtmässige Bearbeitungen zu schützen und der Gefahr
        des Verlusts, einer unbeabsichtigten Veränderung, einer ungewollten Offenlegung oder eines unberechtigten
        Zugriffs entgegenzuwirken. Wie alle Unternehmen können wir Datensicherheitsverletzungen aber nicht mit letzter
        Sicherheit ausschliessen; gewisse Restrisiken sind unvermeidbar.
    </p>
    <p>
        Zu den Sicherheitsmassnahmen technischer Natur gehören z.B. die Verschlüsselung von Daten, Protokollierungen,
        Zugriffsbeschränkungen und die Speicherung von Sicherheitskopien. Sicherheitsmassnahmen organisatorischer Natur
        umfassen z.B. Weisungen an unsere Mitarbeitenden, Vertraulichkeitsvereinbarungen und Kontrollen. Wir
        verpflichten auch unsere Auftragsbearbeiter dazu, angemessene technische und organisatorische
        Sicherheitsmassnahmen zu treffen.
    </p>
    <h1>Wie lange bearbeiten wir Personendaten?</h1>
    <p>Wir bearbeiten und speichern Ihre Personendaten,</p>
    <ul>
        <li>
            solange es für den Zweck der Bearbeitung bzw. damit vereinbare Zwecke erforderlich ist, bei Verträgen in der
            Regel mindestens für die Dauer der Vertragsbeziehung;
        </li>
        <li>
            solange wir ein berechtigtes Interesse an der Speicherung haben. Das kann insbesondere dann der Fall sein,
            wenn wir Personendaten benötigen, um Ansprüche durchzusetzen oder abzuwehren, zu Archivierungszwecken und
            zur Gewährleistung der IT-Sicherheit;
        </li>
        <li>
            solange sie einer gesetzlichen Aufbewahrungspflicht unterliegen. Für bestimmte Daten gilt z.B. eine
            zehnjährige Aufbewahrungsfrist. Für andere Daten gelten jeweils kürzere Aufbewahrungsfristen, z.B. für
            Aufzeichnungen bestimmter Vorgänge im Internet (Log-Daten).
        </li>
    </ul>
    <p>
        Wir orientieren uns grundsätzlich an folgenden Aufbewahrungsfristen, wobei wir im Einzelfall aber davon
        abweichen können:
    </p>
    <ul>
        <li>
            Kundendaten: Kundendaten bewahren wir mindestens während zehn Jahren nach Beendigung der Kundenbeziehung
            auf. Darüber hinaus können wir Kundendaten für Beziehungspflege und Marketingzwecke, sowie anonymisiert für
            Marktauswertungen und -analysen auch darüber hinaus aufbewahren.
        </li>
        <li>
            Vertragsdaten: Vertragsdaten bewahren wir in der Regel während zehn Jahren ab der letzten Vertragsaktivität
            bzw. ab Vertragsende auf. Diese Frist kann jedoch länger sein, soweit dies aus Beweisgründen, aufgrund von
            gesetzlichen oder vertraglichen Vorgaben oder technisch bedingt notwendig ist.
        </li>
        <li>
            Kommunikationsdaten: E-Mails, Mitteilungen via Kontaktformular und schriftliche Korrespondenzen werden in
            der Regel während mindestens zehn Jahren resp. zehn Jahre nach Beendigung der entsprechenden Kundenbeziehung
            aufbewahrt.
        </li>
        <li>
            Technische Daten: Log-Daten bewahren wir in der Regel für ein Jahr auf. Die Speicherdauer von Cookies
            beträgt meist zwischen wenigen Tagen und zwei Jahren, sofern sie nicht unmittelbar nach Ende der Session
            gelöscht werden.
        </li>
    </ul>
    <h1>Welche Rechte haben Sie im Zusammenhang mit der Bearbeitung Ihrer Personendaten?</h1>
    <p>
        Sie haben das Recht, Datenbearbeitungen zu widersprechen, besonders wenn wir Ihre Personendaten auf Basis eines
        berechtigten Interesses bearbeiten und die weiteren anwendbaren Voraussetzungen erfüllt sind. Sie können
        ausserdem Datenbearbeitungen im Zusammenhang mit Direktmarketing (z.B. Werbe-E-Mails) jederzeit widersprechen.
    </p>
    <p>
        Soweit die jeweils anwendbaren Voraussetzungen erfüllt und keine gesetzlichen Ausnahmen anwendbar sind, haben
        Sie zudem folgende Rechte:
    </p>
    <ul>
        <li>das Recht, Auskunft über Ihre bei uns gespeicherten Personendaten zu verlangen;</li>
        <li>das Recht, unrichtige oder unvollständige Personendaten korrigieren zu lassen;</li>
        <li>das Recht, die Löschung oder Anonymisierung Ihrer Personendaten zu verlangen;</li>
        <li>das Recht, die Einschränkung der Bearbeitung Ihrer Personendaten zu verlangen;</li>
        <li>
            das Recht, bestimmte Personendaten in einem strukturierten, gängigen und maschinenlesbaren Format zu
            erhalten;
        </li>
        <li>
            das Recht, eine Einwilligung mit Wirkung für die Zukunft zu widerrufen, soweit eine Bearbeitung auf einer
            Einwilligung beruht.
        </li>
        <li>
            Bitte beachten Sie, dass diese Rechte im Einzelfall ggf. eingeschränkt oder ausgeschlossen sein können, z.B.
            wenn Zweifel an der Identität bestehen oder dies zum Schutz anderer Personen, zur Wahrung von schutzwürdigen
            Interessen oder zur Einhaltung gesetzlicher Verpflichtungen erforderlich ist.
        </li>
        <li>
            Sollten Sie eines der oben genannten Rechte ausüben wollen oder wenn Sie Fragen zur Bearbeitung ihrer
            Personendaten haben, kontaktieren Sie uns bitte jederzeit.
        </li>
        <li>
            Sollten Sie Bedenken haben, ob die Bearbeitung Ihrer Personendaten rechtskonform ist, melden Sie sich bitte
            jederzeit bei uns. Es steht Ihnen auch frei, bei der zuständigen Aufsichtsbehörde Beschwerde einzureichen.
            Die zuständige Aufsichtsbehörde ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (EDÖB).
        </li>
    </ul>
    <h1>Wie können Sie uns kontaktieren?</h1>
    <p>
        Bei Fragen zu dieser Datenschutzerklärung oder zur Bearbeitung Ihrer Personendaten können Sie uns gerne
        jederzeit kontaktieren: LST AG, Täschmattstrasse 19, 6015 Luzern, Schweiz, E-Mail: hallo@lst.team
    </p>
</div>
