import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import { DragulaService } from 'ng2-dragula';
import { GridNode } from '../../../models/node/grid-node.model';
import { Observable, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';
import { KeyFigureForm } from '../../../forms/key-figure.form';
import { TranslateService } from '@ngx-translate/core';
import { FormConfig } from '../../../../../modules/forms/models/form-config.model';
import { NodeType } from '../../../models/node/node-type.model';
import { NodeSubitemEditorComponent } from '../../node-subitem-editor/node-subitem-editor.component';

@Component({
    selector: 'elias-editor-edit-keyfigures',
    templateUrl: './edit-keyfigures.component.html',
    styleUrls: ['edit-keyfigures.component.scss'],
})
export class EditKeyFiguresComponent implements NodeEdit, NodeRepresentation, OnInit, OnDestroy {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: GridNode;
    @Input() nodeViewModel: Observable<GridNode>;

    @Output() contentChange = new EventEmitter<any>();

    public alignVertically = false;
    public keyFigures: any[] = [];
    public columnList: number[] = [];
    cols = 2;
    dropSubscription: Subscription;
    public elementIndexes: number[] = [];
    formConfig!: FormConfig;
    keyFigureLayout = '';

    constructor(
        private dragulaService: DragulaService,
        private sideNavService: SideNavService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.formConfig = KeyFigureForm;
        this.keyFigures = JSON.parse(this.content);

        this.cols = this.node.cols;
        this.keyFigureLayout = this.node.layout;
        this.alignVertically = this.node.alignVertically;

        if (this.alignVertically) {
            const maxLength = Math.max(...this.keyFigures.map((col) => col.length));
            this.elementIndexes = [...Array(maxLength).keys()];
            this.columnList = [...Array(this.cols).keys()];
        }

        const bag: any = this.dragulaService.find('keyfigure-draggable');
        if (bag === undefined) {
            this.dragulaService.createGroup('keyfigure-draggable', {
                removeOnSpill: false,
                mirrorContainer: document.getElementById('keyfigure-editor'),

                accepts(el, target, source, sibling) {
                    const parentContainer = el?.parentElement?.parentElement?.parentElement;
                    const isAlignElement = parentContainer?.classList.contains('align-vertically');
                    const hasDataRowAttribute = parentContainer?.parentElement?.getAttribute('data-row') !== undefined;

                    // Vertical keyfigures
                    if (isAlignElement && hasDataRowAttribute) {
                        return true;
                    }

                    return sibling !== null;
                },

                moves(el, source, handle, siblings) {
                    return (
                        handle.classList.contains('element-button-drag') ||
                        handle.classList.contains('element-button-drag-icon')
                    );
                },
            });

            this.dropSubscription = this.dragulaService
                .drop('keyfigure-draggable')
                .subscribe(({ el, target, source }) => {
                    if (el != null) {
                        this.onNodeMove(el, target, source);
                    }
                    return false;
                });

            if (this.alignVertically) {
                this.dropSubscription.add(
                    this.dragulaService.drag('keyfigure-draggable').subscribe(({ el }) => {
                        this.removeClass(el, 'data-container');
                    })
                );

                this.dropSubscription.add(
                    this.dragulaService.drop('keyfigure-draggable').subscribe(({ el }) => {
                        this.addClass(el, 'data-container');
                    })
                );

                this.dropSubscription.add(
                    this.dragulaService.cancel('keyfigure-draggable').subscribe(({ el }) => {
                        this.addClass(el, 'data-container');
                    })
                );
            }
        }
        this.onContentChange();
    }

    onNodeMove(element: any, target: any, source: any): void {
        const oldPosition = this.getIndex(element);
        const newColIndex = this.getCol(target);
        const oldColIndex = this.getCol(source);
        const newPosition = this.getElementIndex(element, target);

        if (oldColIndex != newColIndex) {
            // if element is moved to another column
            const movedElement = this.keyFigures[oldColIndex][oldPosition];
            // add to new array
            this.keyFigures[newColIndex].splice(newPosition, 0, {
                title: movedElement.title,
                number: movedElement.number,
                unit: movedElement.unit,
                description: movedElement.description,
            });
            // remove from old array
            const removePos = this.keyFigures[oldColIndex].indexOf(movedElement);
            if (removePos > -1) {
                this.keyFigures[oldColIndex].splice(removePos, 1);
            }
        } else {
            // if element is moved within the same column
            this.keyFigures[newColIndex] = this.moveElementInArray(
                this.keyFigures[newColIndex],
                oldPosition,
                newPosition
            );
        }
        this.keyFigures = cloneDeep(this.keyFigures);

        if (this.alignVertically) {
            const maxLength = Math.max(...this.keyFigures.map((col) => col.length));
            this.elementIndexes = [...Array(maxLength).keys()];
            this.keyFigures = this.cleanNestedArray(this.keyFigures);
        }

        this.onContentChange();
    }

    moveElementInArray(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    getElementIndex(el: any, target: any) {
        if (this.alignVertically) {
            return target.getAttribute('data-row');
        }

        return [].slice.call(el.parentElement.children).indexOf(el);
    }

    cleanNestedArray(arr: any[]): any[] {
        return arr
            .filter((item) => item !== undefined)
            .map((item) => (Array.isArray(item) ? this.cleanNestedArray(item) : item));
    }

    getCol(el: any) {
        return el.getAttribute('data-col');
    }

    getIndex(el: any) {
        return el.getAttribute('data-index');
    }

    onContentChange() {
        this.content = JSON.stringify(this.keyFigures);
        this.contentChange.emit(this.content);
    }

    addElement(i) {
        this.keyFigures[i].push({
            title: this.translateService.instant('component.keyfigures.title'),
            number: this.translateService.instant('component.keyfigures.number'),
            unit: this.translateService.instant('component.keyfigures.unit'),
            description: this.translateService.instant('component.keyfigures.description'),
        });
        this.openEditor(this.keyFigures[i][this.keyFigures[i].length - 1], i, this.keyFigures[i].length - 1, 'add');
        this.onContentChange();
    }

    deleteElement(col, index) {
        this.keyFigures[col].splice(index, 1);
        this.onContentChange();
    }

    getColClass() {
        return 12 / this.cols;
    }

    ngOnDestroy() {
        this.dragulaService.destroy('keyfigure-draggable');
        if (this.dropSubscription) {
            this.dropSubscription.unsubscribe();
        }
    }

    openEditor(val, colInd, itemInd, source = null) {
        const onSave = new EventEmitter<any>();
        const onDelete = new EventEmitter<any>();

        const inputs = {
            data: val,
            type: 'keyfigure',
            config: this.formConfig,
            source,
        };
        const outputs = {
            save: onSave,
            delete: onDelete,
        };

        this.sideNavService.setComponent(NodeSubitemEditorComponent, inputs, outputs);

        onSave.subscribe((data) => {
            this.onModifyData(data, colInd, itemInd);
        });

        onDelete.subscribe((value) => {
            if (value) {
                this.onDelete(colInd, itemInd);
            }
        });
    }

    onModifyData(data, colInd, itemInd) {
        for (const key of Object.keys(data)) {
            this.keyFigures[colInd][itemInd][key] = data[key];
        }

        const arr = ['title', 'number', 'description', 'unit'];
        let counter = 0;

        arr.forEach((ele) => {
            if (this.keyFigures[colInd][itemInd].hasOwnProperty(ele)) {
                counter++;
            }
        });

        if (counter === 4) {
            this.onContentChange();
            this.close();
        }
    }

    onDelete(col, itemInd) {
        this.keyFigures[col].splice(itemInd, 1);
        this.onContentChange();
        this.close();
    }

    close() {
        this.sideNavService.toggle();
    }

    public trackByIndex(index: number): number {
        return index;
    }

    private removeClass(element: any, className: string): void {
        if (element.classList.contains(className)) {
            element.classList.remove(className);
        }
    }

    private addClass(element: any, className: string): void {
        if (!element.classList.contains(className)) {
            element.classList.add(className);
        }
    }
}
