import { Injectable } from '@angular/core';
import { Status, StatusStore } from '../state/status/status.store';
import { Observable } from 'rxjs';
import { UpdateService } from '../../core/services/update.service';
import { StatusUpdate } from '../models/status-update.model';
import { UpdateKey } from '../../core/enums/update-key.enum';
import { pluck, tap } from 'rxjs/operators';
import { SystemInformationQuery } from '../state/system-information/system-information.query';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpdateDetectedComponent } from '../dialogs/update-detected/update-detected.component';
import { SystemInformationStore } from '../state/system-information/system-information.store';

@Injectable({
    providedIn: 'root',
})
export class StatusUpdateListener {
    public statusUpdate$: Observable<Status>;

    constructor(
        private dialog: MatDialog,
        private updateService: UpdateService,
        private statusStore: StatusStore,
        private systemInformationQuery: SystemInformationQuery,
        private systemInformationStore: SystemInformationStore
    ) {
        this.statusUpdate$ = this.updateService.getUpdates<StatusUpdate>(UpdateKey.Status).pipe(
            pluck('properties'),
            tap((status) => {
                this.statusStore.update(status);
                this.handleVersionUpdate(status);
            })
        );
    }

    private handleVersionUpdate(status: Status): void {
        if (status.version === this.systemInformationQuery.getValue().version) {
            return;
        }

        this.systemInformationStore.update({ version: status.version });
        this.dialog.open(UpdateDetectedComponent, {
            position: { bottom: '50px', left: '50px' },
            width: '350px',
            height: '180px',
            hasBackdrop: false,
        });
    }
}
