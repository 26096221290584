import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusQuery } from '../../../modules/shared/state/status/status.query';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataProcessingApprovalComponent } from '../../../modules/core/dialogs/data-processing-approval/data-processing-approval.component';
import { Token } from '../../models/token.model';

@Component({
    selector: 'elias-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
    @Input() inMaintenance: boolean = false;
    @Output() userAuthenticated = new EventEmitter<void>();

    public loginForm: UntypedFormGroup;
    public twoFactorForm: UntypedFormGroup;
    public loginErrorMessage?: string;
    public secureLoginErrorMessage?: string;
    public securityLoginMessageSentAgain?: string;
    public isVisible = true;
    public currentStep: 'login' | 'twoFactor' = 'login';
    public isSaving = false;

    public showMaintenanceScheduledWarning$ = this.statusQuery.showMaintenanceScheduledWarning$;
    public maintenanceScheduledWarning$ = this.statusQuery.maintenanceScheduledWarning$;

    constructor(
        public authService: AuthService,
        private fb: UntypedFormBuilder,
        private statusQuery: StatusQuery,
        private translateService: TranslateService,
        private dialog: MatDialog
    ) {
        this.loginForm = this.fb.group({
            username: ['', [Validators.required, Validators.maxLength(255)]],
            password: ['', [Validators.required, Validators.maxLength(255)]],
        });

        this.twoFactorForm = this.fb.group({
            code: ['', [Validators.required]],
        });
    }

    public login(): void {
        this.isSaving = true;
        const username = this.loginForm.controls['username'].value;
        const password = this.loginForm.controls['password'].value;

        this.authService.login(username, password).subscribe(
            async (response) => {
                this.isSaving = false;

                if (response.token) {
                    this.authenticateUser(response);
                    return;
                }

                this.currentStep = 'twoFactor';
            },
            (error) => {
                this.loginErrorMessage = this.translateService.instant('login.networkError');

                if (error.status === 401) {
                    this.loginErrorMessage = this.translateService.instant('login.invalidLogin');
                }

                this.isSaving = false;
            }
        );
    }

    public secureLogin(): void {
        const securityCode = this.twoFactorForm.controls['code'].value;
        this.securityLoginMessageSentAgain = '';
        this.secureLoginErrorMessage = '';

        this.authService.send2FACode(securityCode).subscribe(
            (response) => {
                if (response.token) {
                    this.authenticateUser(response);
                }
            },
            (error) => {
                this.loginErrorMessage = this.translateService.instant('login.networkError');

                if (error.status === 401 || error.status === 400) {
                    this.secureLoginErrorMessage = this.translateService.instant('login.invalidCode');
                }
            }
        );
    }

    public startAgain(): void {
        this.securityLoginMessageSentAgain = '';
        this.secureLoginErrorMessage = '';

        this.authService.startAgain().subscribe((data) => {
            const username = this.loginForm.controls['username'].value;
            const password = this.loginForm.controls['password'].value;

            this.authService.login(username, password).subscribe(() => {});
            this.securityLoginMessageSentAgain = this.translateService.instant('login.securityCodeSentAgain');
        });
    }

    private openDataProcessingApprovalDialog(userLocale: string, userId: string): void {
        const dialog = this.dialog.open(DataProcessingApprovalComponent, {
            width: '1140px',
            height: '840px',
            autoFocus: false,
            disableClose: true,
            data: {
                userId: userId,
            },
        });

        dialog.afterClosed().subscribe((dataProcessingApproved) => {
            if (dataProcessingApproved) {
                this.userAuthenticated.emit();
                return;
            }

            this.loginErrorMessage = '';
            this.currentStep = 'login';
            this.authService.removeToken();
            this.twoFactorForm.reset();
            this.loginForm.reset();
        });
    }

    private authenticateUser(token: Token): void {
        this.translateService.use(token.userLocale);

        if (!token.isDataProcessingApproved) {
            this.openDataProcessingApprovalDialog(token.userLocale, token.userId);
            return;
        }

        this.userAuthenticated.emit();
    }
}
