<button
    (click)="onClick()"
    [ngClass]="{ pulse: pulse, loading: loading }"
    [disabled]="disabled || loading"
    [color]="color"
    [type]="type"
    class="size-{{ size }} color-{{ color }}"
    mat-flat-button>
    <elias-shared-loading [centered]="true" dotSize="small" [isColorful]="false" *ngIf="loading"></elias-shared-loading>
    <span class="wrapper">
        <ng-content></ng-content>
    </span>
</button>
