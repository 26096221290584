<elias-confirmation-dialog
    (dialogSubmitted)="stayLoggedIn()"
    [cancellable]="false"
    buttonLabel="{{ 'modal.autoLogout.stayLoggedIn' | translate }}"
    [description]="
        (timeToLogout !== 1 ? 'modal.autoLogout.message-plural' : 'modal.autoLogout.message-singular')
            | translate : { timeLeft: timeToLogout }
    "
    title="{{ 'modal.autoLogout.title' | translate }}">
</elias-confirmation-dialog>
