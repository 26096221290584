import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MatPaginatorIntlLocale extends MatPaginatorIntl {
    constructor(private translateService: TranslateService) {
        super();
    }

    itemsPerPageLabel = this.translateService.instant('assetBrowser.paginator.itemsPerPage');
}
