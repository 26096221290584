<div class="scrollable-container">
    <div class="container">
        <div class="comment-editor" #commentEditor cdkFocusInitial></div>

        <div fxLayout="row" class="action-buttons" fxLayoutGap="10%" *ngIf="hasContentToSubmit$ | async" @slideInOut>
            <elias-shared-flat-button (buttonClicked)="submitComment()" color="accent" class="btn-save">
                <ng-container *ngIf="action === 'reply'; else comment">
                    {{ 'comments.actions.answer' | translate }}
                </ng-container>
                <ng-template #comment>
                    {{ 'comments.actions.comment' | translate }}
                </ng-template>
            </elias-shared-flat-button>
            <elias-shared-basic-button class="btn-cancel" (buttonClicked)="cancel()">
                {{ 'actions.cancel' | translate }}
            </elias-shared-basic-button>
        </div>
    </div>
</div>
