<div class="preview-button-container">
    <ng-container *ngIf="channelLength === 1">
        <elias-shared-flat-button
            (buttonClicked)="generatePreview(defaultChannel)"
            [loading]="generating"
            class="button-preview"
            color="accent"
            size="big">
            <i class="ei ei-visibility preview-icon"></i> {{ defaultChannel.name }}
            {{ 'previewOptions.preview' | translate }}
        </elias-shared-flat-button>
        <elias-shared-flat-button (buttonClicked)="generatePreview(defaultChannel)" class="icon-preview" color="accent">
            <i class="ei ei-visibility preview-icon"></i>
        </elias-shared-flat-button>
    </ng-container>
    <ng-container *ngIf="channelLength > 1">
        <elias-shared-flat-button color="accent" class="icon-preview" [matMenuTriggerFor]="menu">
            <i class="ei ei-visibility preview-icon"></i>
        </elias-shared-flat-button>

        <mat-button-toggle-group class="button-preview">
            <mat-button-toggle class="split-button" (click)="generatePreview(defaultChannel)" [disabled]="generating">
                <elias-shared-loading
                    *ngIf="generating; else previewIcon"
                    dotSize="small"
                    [isColorful]="false"></elias-shared-loading>
                <ng-template #previewIcon>
                    <i class="ei ei-visibility preview-icon"></i> {{ defaultChannel.name }}
                    {{ 'previewOptions.preview' | translate }}
                </ng-template>
            </mat-button-toggle>
            <mat-button-toggle data-cy="create-preview-toggle-btn" class="drop-down-button" [matMenuTriggerFor]="menu">
                <mat-icon>arrow_drop_down</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let channel of channels; trackBy: trackChannelById">
                <ng-container
                    *ngIf="
                        publication.channelSettings[channel.id] &&
                        publication.channelSettings[channel.id].active &&
                        publication.channelSettings[channel.id].channel.enabled
                    ">
                    <button
                        [attr.data-cy]="'create-preview-btn-' + channel.name | lowercase"
                        mat-menu-item
                        (click)="generatePreview(channel)"
                        fxLayoutGap="5px"
                        class="options">
                        <elias-shared-channel-icon [icon]="channel.icon" size="xsmall"></elias-shared-channel-icon>
                        <span>{{ channel.name }}</span>
                    </button>
                </ng-container>
            </ng-container>
        </mat-menu>
    </ng-container>
</div>
