import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'elias-delete-asset',
    templateUrl: './delete-asset.component.html',
})
export class DeleteAssetComponent {
    constructor(private dialogRef: MatDialogRef<DeleteAssetComponent>) {}

    public onDialogSubmitted(): void {
        this.dialogRef.close(true);
    }
}
