import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'elias-shared-flat-button',
    templateUrl: './flat-button.component.html',
    styleUrls: ['./flat-button.component.scss'],
})
export class FlatButtonComponent {
    @Input() color: 'warn' | 'accent' = 'accent';
    @Input() disabled: boolean = false;
    @Input() loading: boolean = false;
    @Input() pulse: boolean = false;
    @Input() size: 'medium' | 'big' = 'medium';
    @Input() type: 'button' | 'submit' = 'button';

    @Output() buttonClicked = new EventEmitter<void>();

    public onClick(): void {
        this.buttonClicked.emit();
    }
}
