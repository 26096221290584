<div class="container property-editor-container" *ngIf="asset">
    <elias-shared-sidebar-title title="{{ 'propertyEditor.widgetTitle' | translate }}" [buttonFlexLayout]="'end'">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button-icon">
            <i class="ei ei-more-vert"></i>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="remove()">
                <i class="ei ei-trash"></i>{{ 'actions.delete' | translate }}
            </button>
            <button mat-menu-item (click)="download()">
                <i class="ei ei-download"></i>{{ 'actions.download' | translate }}
            </button>
            <button mat-menu-item (click)="replaceFileInput.click()">
                <i class="ei ei-copy"></i>{{ 'actions.replace' | translate }}
            </button>
        </mat-menu>
    </elias-shared-sidebar-title>

    <div>
        <elias-shared-asset-details
            [asset]="asset"
            (thumbnailClicked)="cropImage()"
            [isThumbnailOverlayEnabled]="isCroppable">
            <div thumbnailOverlay class="crop-overlay">
                <i class="ei ei-crop icon-crop"></i>
            </div>
        </elias-shared-asset-details>
        <elias-form
            [formConfig]="formConfig"
            translationKey="propertyEditor"
            [data]="asset"
            (formSubmitted)="onFormSubmitted($event)"
            #form></elias-form>
    </div>

    <ng-container *ngIf="sectionsUsingAsset && sectionsUsingAsset.length > 0">
        <h3 class="title">{{ 'assetStorages.publicationPartOf.title' | translate }}</h3>
        <div class="attention-box">
            <span class="attention-details">{{ 'assetStorages.publicationPartOf.attention' | translate }}</span>
        </div>
        <mat-list>
            <mat-list-item
                *ngFor="let reference of sectionsUsingAsset; trackBy: trackReferenceBySectionId"
                class="list-view">
                <div class="column column-title">
                    <h3 class="title">{{ reference.publicationName }}</h3>
                </div>
                <div class="column column-subtitle">
                    {{ reference.sectionName }}
                </div>
                <div class="column column-language">
                    <span>{{ reference.locale }}</span>
                </div>
                <div class="column column-content">
                    <div class="tags" fxLayout="row wrap" fxLayoutGap="5px">
                        <div
                            class="tag dots"
                            *ngFor="let category of reference.categories | keyvalue; trackBy: trackCategoryById"
                            [ngStyle]="{
                                'background-color': category.value.color
                            }"
                            matTooltip="{{ category.value.name }}"></div>
                    </div>
                </div>
                <div class="column column-meta">
                    <elias-shared-flat-button
                        (buttonClicked)="navigateToPublicationSection(reference)"
                        class="edit-list-button"
                        color="accent">
                        {{ 'actions.go' | translate }}
                    </elias-shared-flat-button>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </ng-container>

    <elias-form-side-nav-buttons
        [forms]="[form]"
        [isSaving]="isSaving"
        (formsCancelled)="close()"></elias-form-side-nav-buttons>

    <input
        #replaceFileInput
        type="file"
        tabindex="-1"
        style="visibility: hidden"
        (change)="replaceFile($event)"
        [accept]="replacementMimeTypes" />
</div>
