<div [formGroup]="formGroup">
    <ng-container *ngIf="category === 'multiple'">
        <div *ngFor="let heading of headings; trackBy: trackByIndex" fxLayout="column" fxLayoutGap="20px">
            <ng-container *ngIf="heading['count'] > 0">
                <h3>{{ 'category.' + heading['head'] | translate }}</h3>
                <mat-chip-list class="chip-list">
                    <ng-container *ngFor="let option of options; trackBy: trackByIndex">
                        <ng-container *ngIf="option.type === heading['head']">
                            <mat-chip
                                [appHighlight]="isActive(option) ? option.color : 'hover-' + option.color"
                                (click)="toggleActivation(option)"
                                [ngClass]="{
                                    active: isActive(option),
                                    'disable-last-category': checkLastAvailable(option)
                                }"
                                class="pill-values"
                                [ngStyle]="{
                                    'background-color': isActive(option) ? option.color : 'white',
                                    color: isActive(option) ? 'white' : '#C1C1C1'
                                }">
                                {{ option.value }}
                                <i
                                    class="ei ei-cancel-full"
                                    matChipRemove
                                    *ngIf="isActive(option)"
                                    (click)="toggleActivation(option)"></i>
                            </mat-chip>
                        </ng-container>
                    </ng-container>
                </mat-chip-list>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="category === 'single'">
        <h3>{{ label }}</h3>
        <mat-chip-list class="chip-list" [ngClass]="{ 'single-divider': category === 'single' }">
            <ng-container *ngFor="let option of options; trackBy: trackByIndex">
                <mat-chip
                    (click)="toggleActivation(option)"
                    [ngClass]="{
                        active: isActive(option),
                        'disable-last-category': checkLastAvailable(option)
                    }"
                    class="pill-values"
                    [ngStyle]="{
                        'background-color': isActive(option) ? option.color : 'white',
                        color: isActive(option) ? 'white' : '#C1C1C1'
                    }">
                    {{ option.value }}
                    <i
                        class="ei ei-cancel-full"
                        matChipRemove
                        *ngIf="isActive(option)"
                        (click)="toggleActivation(option)"></i
                ></mat-chip>
            </ng-container>
        </mat-chip-list>
    </ng-container>
</div>
