import { Injectable } from '@angular/core';
import { Asset } from '../../modules/shared/models/asset.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RequestDto } from '../editor/models/dto/request-dto.model';
import { Observable } from 'rxjs';
import { CroppingEditorComponent } from './cropping-editor/cropping-editor.component';
import { FlashMessageService } from '../../modules/core/services/flash-message.service';
import { AssetCroppingMetadataDto, AssetCroppingResultDto } from './types';
import { ImageCroppingHttpService } from './image-cropping.http-service';

@Injectable({
    providedIn: 'root',
})
export class ImageCroppingService {
    constructor(
        private dialog: MatDialog,
        private flashMessageService: FlashMessageService,
        private imageCroppingHttpService: ImageCroppingHttpService
    ) {}

    public openCroppingEditor(assetId: string, nodeId?: string): Observable<AssetCroppingResultDto> {
        const dialogRef = this.dialog.open(CroppingEditorComponent, {
            data: { assetId, nodeId },
            width: '1200px',
        });

        return dialogRef.afterClosed().pipe(
            filter((metadata): metadata is AssetCroppingMetadataDto => !!metadata),
            switchMap((metadata) => {
                const requestDto = new RequestDto(
                    { assetId },
                    { replace: metadata.replaced ? '1' : '0' },
                    metadata.croppingEditorOutput
                );

                return this.imageCroppingHttpService
                    .cropImage(requestDto)
                    .pipe(map(({ asset }) => ({ asset, metadata })));
            }),
            tap(({ metadata }) => {
                const messageType = metadata.replaced ? 'cropSuccess' : 'cloneAndCropSuccess';
                const message = 'assetBrowser.imageCropping.' + messageType;
                this.flashMessageService.showTranslatedInfo(message);
            }),
            map(({ asset, metadata }) => {
                const result: AssetCroppingResultDto = {
                    replaced: metadata.replaced,
                    asset: asset,
                };

                return result;
            })
        );
    }
}
