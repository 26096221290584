<elias-shared-multi-step-dialog #stepper (cancel)="onCancel()" linear>
    <elias-shared-step
        (stepIncreased)="createSection(nameStepForm.formGroup)"
        title="{{ 'modal.createSection.' + type + '.title' | translate }}"
        subtitle="{{ 'modal.createSection.' + type + '.subtitle' | translate }}"
        class="basic-step"
        [isSave]="nameStepForm.canSave()"
        [hideDots]="true">
        <elias-form [formConfig]="newSectionFormConfig" #nameStepForm></elias-form>
    </elias-shared-step>
</elias-shared-multi-step-dialog>
