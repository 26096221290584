<div class="search-field" [class.active]="isActive">
    <input
        type="search"
        class="search-input"
        [formControl]="searchControl"
        [placeholder]="placeholder"
        (focus)="hasFocus = true"
        (blur)="hasFocus = false" />
    <i class="ei ei-close search-cancel" (click)="clearSearch()"></i>
</div>
