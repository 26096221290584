<elias-shared-stroked-button
    *ngIf="buttonStyle === 'stroked'"
    (buttonClicked)="openAssetManager()"
    id="assetManagerButton"
    class="button"
    color="accent"
    type="button">
    <ng-container *ngIf="text">
        <i class="ei ei-plus-full"></i>
    </ng-container>
    <ng-container *ngIf="!text">
        <i class="ei ei-copy"></i>
    </ng-container>
    <span *ngIf="text" class="assetButtonText">{{ text | translate }}</span>
    <span *ngIf="!text" class="assetButtonText">{{ 'assetBrowser.change.' + type | translate }}</span>
</elias-shared-stroked-button>
<elias-shared-flat-button
    *ngIf="buttonStyle === 'flat'"
    (buttonClicked)="openAssetManager()"
    id="assetManagerButton"
    class="button"
    type="button">
    <ng-container *ngIf="text">
        <i class="ei ei-plus-full"></i>
    </ng-container>
    <ng-container *ngIf="!text">
        <i class="ei ei-copy"></i>
    </ng-container>
    <span *ngIf="text" class="assetButtonText">{{ text | translate }}</span>
    <span *ngIf="!text" class="assetButtonText">{{ 'assetBrowser.change.' + type | translate }}</span>
</elias-shared-flat-button>
