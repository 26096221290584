import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'elias-shared-basic-button',
    templateUrl: './basic-button.component.html',
    styleUrls: ['./basic-button.component.scss'],
})
export class BasicButtonComponent {
    @Input() size: 'small' | 'medium' | 'big' = 'medium';
    @Input() type: 'button' | 'submit' = 'button';
    @Output() buttonClicked = new EventEmitter<MouseEvent>();

    public onClick(event: MouseEvent): void {
        this.buttonClicked.emit(event);
    }
}
