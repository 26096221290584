import { Component, ContentChildren, EventEmitter, OnInit, Output, QueryList } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { StepComponent } from './step.component';

@Component({
    selector: 'elias-shared-multi-step-dialog',
    templateUrl: './multi-step-dialog.component.html',
    styleUrls: ['./multi-step-dialog.component.scss'],
    providers: [{ provide: CdkStepper, useExisting: MultiStepDialogComponent }],
})
export class MultiStepDialogComponent extends CdkStepper implements OnInit {
    @Output() cancel = new EventEmitter();
    @ContentChildren(StepComponent) _steps: QueryList<StepComponent>;

    public autofocus = true;

    get selectedStep(): StepComponent {
        return this.getActiveStep();
    }

    ngOnInit(): void {
        this.refreshAutofocus();

        this.selectionChange.subscribe((el) => {
            this.refreshAutofocus();
        });
    }

    onClick(index: number): void {
        if (index < this.selectedIndex) {
            this.selectedIndex = index;
        } else if (this.getActiveStep().isSave) {
            this.selectedIndex = index;
        }
    }

    getActiveStep(): StepComponent {
        return this._steps.toArray()[this.selectedIndex];
    }

    onCancel(): void {
        this.cancel.emit();
    }

    next() {
        this._steps.toArray()[this.selectedIndex].nextStep();
    }

    goToNext() {
        super.next();
    }

    trackByIndex(index, item) {
        return index;
    }

    refreshAutofocus(): void {
        this.autofocus = false;

        setTimeout(() => {
            this.autofocus = true;
        });
    }
}
