import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { UntypedFormBuilder } from '@angular/forms';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'elias-form-element-radio',
    templateUrl: './form-radio.component.html',
    styleUrls: ['./form-radio.component.scss'],
})
export class FormRadioComponent extends BasicFormElementComponent implements OnInit {
    @Input() options: any;

    selectedID: any;

    constructor(private fb: UntypedFormBuilder, injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.initValue) {
            this.selectedID = cloneDeep(this.initValue);
        }
        this.formGroup.get(this.name).setValue(this.selectedID);
    }

    toggleValue(value) {
        this.formGroup.get(this.name).setValue(value);
    }

    trackByIndex(index, item) {
        return index;
    }
}
