import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef,
    MatLegacyDialogState as MatDialogState,
} from '@angular/material/legacy-dialog';
import { AutoLogoutComponent } from '../../modules/shared/dialogs/auto-logout/auto-logout.component';
import { UserActivityService } from '../../modules/shared/services/user-activity.service';
import { TokenService } from './token.service';
import { NEVER, Observable, timer } from 'rxjs';
import { LocalStorageKeys } from '../../modules/shared/enums/local-storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class AutoLogoutService {
    public autoLogout$: Observable<any>;
    private autoLogoutDialog: MatDialogRef<AutoLogoutComponent> | undefined;

    constructor(
        public dialog: MatDialog,
        private tokenService: TokenService,
        private userActivityService: UserActivityService
    ) {
        this.autoLogout$ = this.userActivityService.userActivity$.pipe(
            tap(() => {
                this.clearOutdatedLocalStorageValues();

                if (this.isAutoLogoutInProgress()) {
                    this.openAutoLogoutDialog();
                }
            }),
            switchMap(() => {
                if (!this.tokenService.isTokenExpired()) {
                    return timer(this.tokenService.getTokenLifetime());
                }
                return NEVER;
            }),
            tap(() => {
                if (this.tokenService.isTokenExpired()) return;
                this.openAutoLogoutDialog();
            })
        );
    }

    private clearOutdatedLocalStorageValues() {
        if (!localStorage.getItem(LocalStorageKeys.AutoLogoutStartDate)) {
            return;
        }

        const autoLogoutStartDate = Date.parse(localStorage.getItem(LocalStorageKeys.AutoLogoutStartDate) as string);
        const currentDate = new Date();

        if (currentDate.getTime() - autoLogoutStartDate > AutoLogoutComponent.AUTOLOGOUT_OFFSET) {
            localStorage.removeItem(LocalStorageKeys.AutoLogoutStartDate);
        }
    }

    private isAutoLogoutInProgress(): boolean {
        return (
            !this.tokenService.isTokenExpired() && localStorage.getItem(LocalStorageKeys.AutoLogoutStartDate) !== null
        );
    }

    private openAutoLogoutDialog() {
        if (!this.autoLogoutDialog || this.autoLogoutDialog?.getState() === MatDialogState.CLOSED) {
            this.autoLogoutDialog = this.dialog.open(AutoLogoutComponent);
        }
    }
}
