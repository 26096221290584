import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import { DragulaService } from 'ng2-dragula';
import { Observable } from 'rxjs';
import { Asset } from '../../../../../modules/shared/models/asset.model';
import { ImageGridForm } from '../../../forms/image-grid.form';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';
import { NodeSubitemEditorComponent } from '../../node-subitem-editor/node-subitem-editor.component';
import { cloneDeep } from 'lodash-es';
import { AssetBrowserButtonComponent } from '../../../../asset-browser/components/asset-browser-button/asset-browser-button.component';
import { FormConfig } from '../../../../../modules/forms/models/form-config.model';
import { NodeType } from '../../../models/node/node-type.model';
import { ImageCroppingService } from '../../../../image-cropping/image-cropping.service';

@Component({
    selector: 'elias-editor-edit-imagegrid',
    styleUrls: ['edit-imagegrid.component.scss'],
    templateUrl: './edit-imagegrid.component.html',
})
export class EditImageGridComponent implements NodeEdit, NodeRepresentation, OnInit, OnDestroy, AfterViewInit {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: any;
    @Input() nodeViewModel: Observable<any>;

    @Output() contentChange = new EventEmitter<any>();

    itemFieldSettings;
    objectKeys = Object.keys;
    order;
    objectData = [];
    images = [];
    cols = 3;
    aspectRatio;
    formConfig: FormConfig = { elements: [] };

    @ViewChild('assetBrowserButton') assetBrowserButton: AssetBrowserButtonComponent;

    constructor(
        private dragulaService: DragulaService,
        private imageCroppingService: ImageCroppingService,
        private imageGridForm: ImageGridForm,
        private sideNavService: SideNavService
    ) {
        this.dragulaService.drop('images-draggable').subscribe((value) => {
            this.onContentChange();
        });
    }

    ngOnInit() {
        this.formConfig = this.imageGridForm.getConfiguration('images');
        this.parseContent();
        this.cols = this.node.cols;
        if (this.node.aspectRatio) {
            this.aspectRatio = this.node.aspectRatio;
        } else {
            this.nodeViewModel.subscribe((nodeViewModel) => {
                if (nodeViewModel !== null) {
                    this.aspectRatio = nodeViewModel.aspectRatio;
                }
            });
        }

        const bag: any = this.dragulaService.find('images-draggable');
        if (bag === undefined) {
            this.dragulaService.createGroup('images-draggable', {
                removeOnSpill: false,

                moves(el, source, handle, siblings) {
                    return (
                        handle.classList.contains('element-button-drag') ||
                        handle.classList.contains('element-button-drag-icon')
                    );
                },
            });

            this.dragulaService.drag('images-draggable').subscribe(({ name, el, source }) => {
                document.onmousemove = (e) => {
                    const event = e || window.event;
                    const mouseY = event['pageY'];
                    const scrollTop =
                        window.scrollY ||
                        window.pageYOffset ||
                        document.body.scrollTop +
                            ((document.documentElement && document.documentElement.scrollTop) || 0);
                    const scrollBottom = scrollTop + window.innerHeight;
                    const elementHeight = (el as HTMLElement).getBoundingClientRect().height;

                    if (mouseY < scrollTop + 93) {
                        document.querySelector('.scroll-container').scrollBy(0, -15);
                    } else if (mouseY + elementHeight > scrollBottom) {
                        document.querySelector('.scroll-container').scrollBy(0, 15);
                    }
                };
            });

            this.dragulaService.dragend('images-draggable').subscribe((value) => {
                document.onmousemove = null;
            });
        }
        this.preFormatData();
    }

    parseContent() {
        this.images = JSON.parse(this.content);
        this.itemFieldSettings = JSON.parse(this.node.itemFieldSettings);
        this.order = Object.keys(this.itemFieldSettings);
    }

    onContentChange() {
        this.images = this.images.filter((image) => image.image);
        this.content = JSON.stringify(this.images);
        this.preFormatData();
        this.contentChange.emit(this.content);
    }

    validateURL(content) {
        if (content.includes('https')) return content;
        else if (content.includes('http')) return content.replace('http', 'https');
        else return 'https://' + content;
    }

    preFormatData() {
        this.objectData = [];
        const cloneImage = cloneDeep(this.images);
        for (const i of Object.keys(cloneImage)) {
            const res = Object.keys(cloneImage[i])
                .map((a) => ({ [a]: cloneImage[i][a] }))
                .sort(
                    (a, b) => this.order.indexOf(Object.keys(a)[0]) + 1 - (this.order.indexOf(Object.keys(b)[0]) + 1)
                );

            const newData = res.reduce(function (r, c) {
                return Object.assign(r, c);
            }, {});
            this.objectData.push(newData);
        }
    }

    onAssetsSelected(assets: Asset[]) {
        assets.map((asset: Asset) => {
            const newImage = { image: asset.id, description: '', link: '' };
            this.images.push(newImage);
        });

        let counter = 0;
        this.images.forEach((prop) => {
            if (prop.hasOwnProperty('image') && prop['image'] != null) {
                counter++;
            }
        });

        if (counter === this.images.length) {
            this.onContentChange();
        }
    }

    ngOnDestroy(): void {
        this.dragulaService.destroy('images-draggable');
    }

    ngAfterViewInit(): void {
        // auto open asset manager
        if (this.images.length == 0) {
            if (this.assetBrowserButton) {
                this.assetBrowserButton.openAssetManager();
            }
        }
    }

    openEditor(image, index) {
        const onSave = new EventEmitter<any>();
        const onDelete = new EventEmitter<any>();

        const inputs = {
            data: image,
            config: this.formConfig,
        };
        const outputs = {
            save: onSave,
            delete: onDelete,
        };

        this.sideNavService.setComponent(NodeSubitemEditorComponent, inputs, outputs);

        onSave.subscribe((data) => {
            this.onModifyData(data, index);
        });

        onDelete.subscribe((value) => {
            if (value) {
                this.onDelete(index);
            }
        });
    }

    onModifyData(data, index) {
        for (const key of Object.keys(data)) {
            this.images[index][key] = data[key];
        }

        if (this.images[index]['link']) {
            this.images[index]['link'] = this.validateURL(this.images[index]['link']);
        }

        const arr = ['image', 'title', 'subtitle', 'description', 'source', 'link'];
        let counter = 0;
        arr.forEach((ele) => {
            if (this.images[index].hasOwnProperty(ele)) {
                counter++;
            }
        });

        if (counter === 6) {
            this.onContentChange();
            this.close();
        }
    }

    // issues with synchronous call
    onDelete(index) {
        const dataArray = this.images.filter((n) => n != undefined);
        dataArray.splice(index, 1);
        this.images = JSON.parse(JSON.stringify(dataArray));
        this.onContentChange();
        this.close();
    }

    close() {
        this.sideNavService.close();
    }

    public openCroppingEditor(assetId: string, index: number): void {
        if (!assetId) {
            return;
        }

        this.imageCroppingService.openCroppingEditor(assetId, this.node.id).subscribe((result) => {
            if (!result.replaced) {
                this.images[index]['image'] = result.asset.id;
                this.onContentChange();
            }
        });
    }

    public trackByKey(index: number, key: string): string {
        return key;
    }

    public trackImageById(index: number, image: any): string {
        return image.image;
    }
}
