<elias-shared-multi-step-dialog *ngIf="showDialog" (cancel)="onCancel()">
    <elias-shared-step
        (stepIncreased)="submitCrop()"
        [isSave]="canSave"
        [hideDots]="true"
        title="{{ 'assetBrowser.imageCropping.title' | translate }}"
        nextButtonLabel="{{ saveButtonText | translate }}">
        <div class="wrapper">
            <div class="side-panel">
                <h2>{{ 'assetBrowser.imageCropping.aspectRatio' | translate }}</h2>
                <mat-chip-list class="mat-chip-list-stacked">
                    <mat-chip
                        style="width: auto"
                        [selected]="selectedAspectRatio === ratio.factor"
                        *ngFor="let ratio of aspectRatios$ | async; trackBy: trackAspectRatioById"
                        (click)="changeAspectRatio(ratio.factor)">
                        {{ ratio.name
                        }}<ng-container *ngIf="ratio.visualFactor"> ({{ ratio.visualFactor }})</ng-container>
                    </mat-chip>
                    <mat-chip
                        [selected]="selectedAspectRatio === undefined"
                        (click)="changeAspectRatio()"
                        style="width: auto"
                        >{{ 'assetBrowser.imageCropping.freeMode' | translate }}</mat-chip
                    >
                </mat-chip-list>
            </div>
            <div class="cropping-area">
                <div class="inner">
                    <img
                        [src]="image | safeUrl"
                        class="image-to-crop"
                        alt=""
                        *ngIf="image$ | async as image; else loading" />
                    <ng-template #loading>
                        <elias-shared-loading [centered]="true"></elias-shared-loading>
                    </ng-template>
                </div>
            </div>
        </div>
    </elias-shared-step>
</elias-shared-multi-step-dialog>
