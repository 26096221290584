<div [formGroup]="formGroup">
    <div
        fxLayout="row"
        fxLayoutGap="30px"
        fxLayoutAlign="start space-between"
        *ngIf="ObjectKeys(settings).length && settings.filter">
        <div
            class="filters"
            fxFlex="fill"
            fxLayout="row"
            fxLayoutGap="30px"
            [ngClass]="{ styling: renderType === 'small-card' }">
            <elias-shared-search-field
                data-cy="search-input"
                [placeholder]="'userlist.filter.search' | translate"
                (searchChange)="applyFilter($event)">
            </elias-shared-search-field>

            <elias-shared-filter-dropdown
                class="filter-dropdown"
                title="{{ 'user.filter.roles' | translate }}"
                [options]="userRoleOptions"
                (changeValue)="onOptionSelected($event)"></elias-shared-filter-dropdown>
        </div>
    </div>
    <div *ngIf="(filteredUser$ | async)?.length > 0; else showNoRecord">
        <div fxLayout="row wrap" fxLayoutGap="20px grid" [ngClass]="{ styling: renderType === 'small-card' }">
            <ng-container *ngFor="let user of filteredUser$ | async; trackBy: trackByItemId; let i = index">
                <div *ngIf="user.enabled">
                    <mat-card
                        class="person"
                        data-cy="person"
                        [ngClass]="{
                            active: isActive(user),
                            personal: isActive(user) && !isChiefEditor(user),
                            'disable-user': isChiefEditor(user) || isSuperAdmin(user),
                            'disable-last-user':
                                selectedUsers.length === 1 && isActive(user) && formGroup.controls.chiefEditors,
                            'small-card': renderType === 'small-card'
                        }"
                        (click)="toggleActivation(user)">
                        <mat-card-header fxLayout="column">
                            <i *ngIf="isActive(user) && !isChiefEditor(user)" class="ei ei-done done"></i>
                            <div fxLayout="row" fxLayoutGap="10px">
                                <div mat-card-avatar>
                                    <elias-shared-user-avatar
                                        [user]="user"
                                        [hideToolTip]="true"
                                        [size]="'medium'"></elias-shared-user-avatar>
                                </div>
                                <mat-card-title>{{
                                    user.firstName + ' ' + user.lastName | strlen : 20
                                }}</mat-card-title>
                            </div>
                        </mat-card-header>
                        <mat-card-content *ngIf="renderType === 'normal'">
                            <div class="tags" fxLayout="row" fxLayoutGap="6px">
                                <div
                                    *ngFor="let role of user.roles; trackBy: trackByIndex"
                                    class="tag dots"
                                    [ngStyle]="{ 'background-color': userRoleColor[role] }"
                                    matTooltip="{{ 'user.roles.' + role | translate }}"></div>
                            </div>
                        </mat-card-content>
                        <mat-card-footer *ngIf="renderType === 'normal'">
                            <ng-container *ngIf="user.id !== usersQuery.getLoggedInUserId() || isAdmin(user)">
                                <elias-shared-flat-button *ngIf="!isActive(user)" class="select-button" color="accent">
                                    {{ 'publication.select' | translate | uppercase }}
                                </elias-shared-flat-button>
                                <elias-shared-flat-button *ngIf="isActive(user)" class="select-button" color="accent">
                                    {{ 'publication.deselect' | translate | uppercase }}
                                </elias-shared-flat-button>
                            </ng-container>
                        </mat-card-footer>
                    </mat-card>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-template #showNoRecord>
        <div>{{ 'userlist.noRecordError' | translate }}</div>
    </ng-template>
</div>
