import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'elias-data-protection',
    templateUrl: './data-protection.component.html',
})
export class DataProtectionComponent {
    constructor(private dialogRef: MatDialogRef<DataProtectionComponent>) {}

    public onCancel(): void {
        this.dialogRef.close(false);
    }
}
