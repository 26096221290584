import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessageService } from '../../../../core/services/flash-message.service';

@Component({
    selector: 'elias-form-element-basic',
    templateUrl: './form-basic-component.component.html',
    styleUrls: ['./form-basic-component.component.css'],
})
export class BasicFormElementComponent implements OnInit {
    @Input() deprecated: boolean = false;
    @Input() disabled: boolean = false;
    @Input() disabledOptions: any;
    @Input() formGroup: UntypedFormGroup;
    @Input() hint: string;
    @Input() initValue: any;
    @Input() label = '';
    @Input() name: string;
    @Input() placeholder = '';
    @Input() required = false;
    @Input() settings;
    @Input() translationKey: string;
    @Input() type: string;

    protected translateService;
    protected flashMessageService;
    public control: AbstractControl;

    constructor(protected injector: Injector) {
        this.flashMessageService = injector.get(FlashMessageService);
        this.translateService = injector.get(TranslateService);
    }

    ngOnInit() {
        // Set defaults
        this.translationKey = this.translationKey ? this.translationKey + '.' : '';

        this.label = this.label
            ? this.translateService.instant(this.translationKey + this.label)
            : this.translateService.instant(this.translationKey + this.name);

        this.placeholder = this.placeholder
            ? this.translateService.instant(this.translationKey + this.label)
            : this.label;

        this.control = this.formGroup.controls[this.name];
    }
}
