<elias-accent-dialog title="{{ 'assetBrowser.imageCropping.warning' | translate }}" [showCloseButton]="false">
    <div class="body">
        <p>{{ 'assetBrowser.imageCropping.listOfUsedPublications' | translate }}</p>
        <div class="publication-list">
            <div
                class="publication-name"
                *ngFor="let publicationUsingAsset of this.publicationsUsingAsset; trackBy: trackBySectionId">
                {{ publicationUsingAsset.publicationName }}
            </div>
        </div>
        <p>{{ 'assetBrowser.imageCropping.replaceImageMessage' | translate }}</p>
        <p>{{ 'assetBrowser.imageCropping.saveAsCopyMessage' | translate }}</p>
    </div>

    <div footer class="footer-bar">
        <elias-shared-basic-button (buttonClicked)="onCancel()" class="modal-cancel-button" data-karma="cancel-button">
            {{ 'actions.cancel' | translate }}
        </elias-shared-basic-button>
        <div class="confirm-buttons">
            <elias-shared-stroked-button
                (buttonClicked)="onConfirm(true)"
                class="modal-save-button"
                data-karma="replace-button"
                color="warn">
                {{ 'assetBrowser.imageCropping.replaceImage' | translate }}
            </elias-shared-stroked-button>
            <elias-shared-flat-button
                cdkFocusInitial
                color="warn"
                (buttonClicked)="onConfirm(false)"
                class="modal-save-button save-as-copy-button"
                data-karma="save-as-copy-button">
                {{ 'assetBrowser.imageCropping.saveAsCopy' | translate }}
            </elias-shared-flat-button>
        </div>
    </div>
</elias-accent-dialog>
